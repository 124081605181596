import { useAppError } from 'store/selectors';
import PropTypes from 'prop-types';
import ErrorPage from 'components/error-page/ErrorPage';

const AppError = ({ children }) => {
  const appError = useAppError();

  return (
    <>
      {appError && (
        <div className='h-screen flex justify-center items-center'>
          <div className='w-full'>
            <ErrorPage
              title={appError.errorTitle || 'Unexpected Error Occurred'}
              subtitle={appError.message}
              tryAgainText={appError?.retryText || undefined}
              onTryAgain={appError?.retryFunction || undefined}
            />
          </div>
        </div>
      )}

      {!appError && children}
    </>
  );
};

AppError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppError;
