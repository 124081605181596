import { ConfidenceSelector } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const StudentSubmissionConfidence = ({
  onSelectConfidence,
  selectedConfidence,
}) => {
  return (
    <div>
      <div className='text-[#344054] text-xl font-semibold mb-8 '>
        Second, how confident were you on thi assessment?
      </div>

      <ConfidenceSelector
        onSelect={onSelectConfidence}
        value={selectedConfidence}
        isVertical={true}
      />
    </div>
  );
};

StudentSubmissionConfidence.defaultProps = {
  onSelectConfidence: () => {},
  selectedConfidence: null,
};

StudentSubmissionConfidence.propTypes = {
  onSelectConfidence: PropTypes.func,
  selectedConfidence: PropTypes.string,
};

export default StudentSubmissionConfidence;
