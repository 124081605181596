import { useClassroom, useUser } from 'store/selectors';
import { getMembers } from 'apis/';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { useEffect, useState } from 'react';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import DashboardAvatars from './DashboardAvatars';
import DashboardStudentNames from './DashboardStudentNames';
import DashboardStudentsField from './DashboardStudentsField';
import DashboardHeaderLoader from './DashboardHeaderLoader';
import PropTypes from 'prop-types';

const DashboardHeader = ({ onSelectStudent }) => {
  const [membersLoading, setMembersLoading] = useState(false);
  const [membersError, setMembersError] = useState('');

  const loggedInUser = useUser();
  const isTeacher = loggedInUser?.role === 'teacher';

  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const { students } = useClassroom();

  const [studentsToShow, setStudentsToShow] = useState(
    isTeacher ? students : [loggedInUser],
  );

  const handleStudentChange = (selected) => {
    const result =
      selected === null
        ? students
        : students.filter((student) => selected === student.id);
    setStudentsToShow(() => result);
    onSelectStudent(selected);
  };

  const fetchMembers = async () => {
    try {
      if (students?.length > 0) return;
      setMembersLoading(true);
      setMembersError('');
      const response = await getMembers();
      dispatch(setClassroomDetails({ members: response }));
    } catch (e) {
      errorHandler(e, () => {
        setMembersLoading(false);
        setMembersError(e.message);
      });
    }
  };

  useEffect(() => {
    if (isTeacher) {
      fetchMembers();
    }
  }, []);

  return (
    <>
      {membersError && <div>Error Occurred when fetching user details</div>}

      {!membersError && membersLoading && (
        <DashboardHeaderLoader isTeacher={isTeacher} />
      )}

      {!membersError && !membersLoading && (
        <div className='lg:flex justify-between items-center'>
          <div className='md:flex items-center mb-4 lg:mb-0'>
            <div className='mr-4 mb-4 md:mb-0'>
              <DashboardAvatars users={studentsToShow} />
            </div>

            <div>
              <div className='text-[#344054] text-xl font-semibold'>
                {studentsToShow.length > 1 && 'All Students'}
                {studentsToShow.length === 1 && studentsToShow[0]?.display_name}
              </div>
              <DashboardStudentNames users={studentsToShow} />
            </div>
          </div>

          {isTeacher && (
            <div className='lg:w-1/4'>
              <DashboardStudentsField
                users={students}
                onSelect={handleStudentChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

DashboardHeader.defaultProps = {
  onSelectStudent: () => {},
};

DashboardHeader.propTypes = {
  onSelectStudent: PropTypes.func,
};

export default DashboardHeader;
