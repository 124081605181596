import EmptyIllustration from '@gonurture/design-system/dist/assets/empty-illustration.svg';
import PageWithIllustration from '../page-with-illustration/PageWithIllustration';
import PropTypes from 'prop-types';

const EmptyPage = ({ title, subtitle, tryAgainText, onTryAgain }) => {
  return (
    <PageWithIllustration
      illustration={EmptyIllustration}
      title={title}
      subtitle={subtitle}
      buttonText={tryAgainText}
      onAction={onTryAgain}
    />
  );
};

EmptyPage.defaultProps = {
  title: '',
  subtitle: '',
  tryAgainText: '',
  onTryAgain: () => {},
};

EmptyPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tryAgainText: PropTypes.string,
  onTryAgain: PropTypes.func,
};

export default EmptyPage;
