import { Skeleton } from '@gonurture/design-system';

const DashboardGraphLoader = () => {
  return (
    <div className='lg:flex lg:space-x-4'>
      <div className='lg:w-3/4 mb-4 lg:mb-0'>
        <Skeleton className='w-full h-[300px]' />
        <div className='flex justify-center mt-3'>
          <Skeleton className='w-36 h-4' />
        </div>
      </div>

      <div className='lg:w-1/4'>
        <div className='flex flex-col items-center mb-5'>
          <Skeleton className='w-16 h-16 rounded-full' />
          <Skeleton className='w-36 h-4 mt-1' />
        </div>

        <div className='border border-[#D0D5DD] rounded-lg p-3 mb-5'>
          <Skeleton className='w-36 h-4 mb-3' />
          <Skeleton className='w-36 h-4' />
        </div>

        <div className='border border-[#D0D5DD] rounded-lg p-3'>
          <Skeleton className='w-36 h-4 mb-3' />
          <Skeleton className='w-36 h-4' />
        </div>
      </div>
    </div>
  );
};

export default DashboardGraphLoader;
