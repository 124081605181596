import { useEffect, useState } from 'react';
import { useUserAssignments, useClassroom } from 'store/selectors';
import { useToast } from '@gonurture/design-system';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { addUserAssignments } from 'store/reducers/user-assignments-reducer';
import { userAssignmentsList } from 'apis/';
import AssignmentListLoader from '../../shared/AssignmentListLoader';
import ErrorPage from 'components/error-page/ErrorPage';
import EmptyPage from 'components/empty-page/EmptyPage';
import UserAssignmentCard from './UserAssignmentCard';

const UserAssignmentList = () => {
  const [userAssignmentsLoading, setUserAssignmentsLoading] = useState(false);
  const [userAssignmentsError, setUserAssignmentsError] = useState('');
  const { channelId } = useClassroom();
  const userAssignments = useUserAssignments();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const fetchUserAssignments = async () => {
    try {
      setUserAssignmentsLoading(true);
      setUserAssignmentsError('');

      const { user_works: userAssignments } =
        await userAssignmentsList(channelId);
      dispatch(addUserAssignments(userAssignments));
      setUserAssignmentsLoading(false);
    } catch (e) {
      setUserAssignmentsLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignments',
          variant: 'error',
        });
        setUserAssignmentsError(e.message);
      });
    }
  };

  useEffect(() => {
    fetchUserAssignments();
  }, []);

  return (
    <>
      {userAssignmentsError && (
        <ErrorPage
          title='Error occurred when fetching assignments'
          subtitle={userAssignmentsError}
          tryAgainText='Retry'
          onTryAgain={fetchUserAssignments}
        />
      )}

      {!userAssignmentsError && userAssignmentsLoading && (
        <AssignmentListLoader />
      )}

      {!userAssignmentsError &&
        !userAssignmentsLoading &&
        userAssignments.length > 0 && (
          <div>
            {userAssignments.map((userAssignment) => (
              <div className='mb-6' key={userAssignment.id}>
                <UserAssignmentCard userAssignment={userAssignment} />
              </div>
            ))}
          </div>
        )}

      {!userAssignmentsError &&
        !userAssignmentsLoading &&
        userAssignments.length === 0 && (
          <EmptyPage
            title='Looks like there are no assessments in this channel yet.'
            subtitle='You will be able to access assessments when your teacher creates and assigns an assessment to you'
          />
        )}
    </>
  );
};

export default UserAssignmentList;
