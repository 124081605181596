import { createRequest } from '../services/ApiService';

export const userActivity = async (channelId, data) => {
  try {
    return await createRequest({
      url: `user_activities`,
      method: 'post',
      payload: data,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
