import {
  MultiSelect,
  pluralize,
  Separator,
  useToast,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { SUBJECTS, TEACHER_ROLES } from '../teacher-onboarding/constants';
import { useClassroom } from 'store/selectors';
import { useEffect, useState } from 'react';
import { updateChannel } from 'apis/';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import AttachmentsUploadAndDisplay from 'components/attachments-upload-and-display/AttachmentsUploadAndDisplay';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import MultiSelectWithCheckboxOptions from 'components/multi-select-with-checkbox-options/MultiSelectWithCheckboxOptions';
import { MAX_CURRICULUM_DOCUMENTS } from '../teacher-onboarding/TeacherOnboardingUploadCurriculum';

const ClassroomSettings = ({ onBack }) => {
  const [classroomSubject, setClassroomSubject] = useState(null);
  const [teacherRoles, setTeacherRoles] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const subjectOptions = SUBJECTS.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const teacherRoleOptions = TEACHER_ROLES.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const {
    attachments: curriculumAttachments,
    subject,
    teacherRoles: storeTeacherRoles,
    channelId,
  } = useClassroom();

  const dispatch = useDispatch();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();

  const handleSubjectChange = async (item) => {
    try {
      setClassroomSubject(item.value);
      const response = await updateChannel(channelId, { subject: item.value });
      const { subject } = response;
      dispatch(setClassroomDetails({ subject }));
      toast({
        description: 'Your changes have been saved.',
      });
    } catch (error) {
      errorHandler(error, () => {
        toast({
          description: 'Could not save your changes. Please try again.',
          variant: 'destructive',
        });
      });
    }
  };

  const handleTeacherRoleChange = async (values) => {
    try {
      setTeacherRoles(() => values);
      const response = await updateChannel(channelId, {
        teacher_roles: values,
      });
      const { teacher_roles } = response;
      dispatch(setClassroomDetails({ teacherRoles: teacher_roles }));
      toast({
        description: 'Your changes have been saved.',
      });
    } catch (error) {
      errorHandler(error, () => {
        toast({
          description: 'Could not save your changes. Please try again.',
          variant: 'destructive',
        });
      });
    }
  };

  const handleAttachmentAdded = (attachment) => {
    const updatedAttachments = [...attachments, attachment];
    dispatch(setClassroomDetails({ attachments: updatedAttachments }));
  };

  const handleAttachmentDelete = (attachment) => {
    const updatedAttachments = attachments.filter(
      (a) => a.id !== attachment.id,
    );
    dispatch(setClassroomDetails({ attachments: updatedAttachments }));
  };

  useEffect(() => {
    setClassroomSubject(subject);
  }, [subject]);

  useEffect(() => {
    setAttachments(curriculumAttachments);
  }, [curriculumAttachments]);

  useEffect(() => {
    setTeacherRoles(() => storeTeacherRoles);
  }, [storeTeacherRoles]);

  return (
    <div>
      <div className=''>
        <HeaderWithBackButton
          header={
            <div className='text-[#181D27] text-xl font-merriweather'>
              Classroom Settings
            </div>
          }
          onBack={onBack}
        />
      </div>

      {/*Subject*/}
      <div className='md:flex'>
        <div className='xl:w-1/4 md:w-1/3'>
          <div>
            <div className='mb-1 text-[#414651] text-sm font-semibold'>
              Subject <span className='text=[#7F56D9]'>*</span>
            </div>
            <div className='text-[#535862] text-sm'>
              Choose what subject this class is for.
            </div>
          </div>
        </div>

        <div className='xl:w-2/4 md:w-2/3'>
          <div className='md:ml-4'>
            <MultiSelect
              id='subjects'
              multi={false}
              options={subjectOptions}
              value={{ label: classroomSubject, value: classroomSubject }}
              onValuesChange={handleSubjectChange}
            />
          </div>
        </div>
      </div>

      <Separator className='my-8' />

      {/*Teacher Roles*/}
      <div className='md:flex'>
        <div className='xl:w-1/4 md:w-1/3'>
          <div>
            <div className='mb-1 text-[#414651] text-sm font-semibold'>
              My Roles <span className='text=[#7F56D9]'>*</span>
            </div>
            <div className='text-[#535862] text-sm'>
              Choose what best describes your role’s in this class.
            </div>
          </div>
        </div>

        <div className='xl:w-2/4 md:w-2/3'>
          <div className='md:ml-4'>
            <MultiSelectWithCheckboxOptions
              values={teacherRoles}
              options={teacherRoleOptions}
              onChange={handleTeacherRoleChange}
            />
          </div>
        </div>
      </div>

      <Separator className='my-8' />

      {/*Curriculum Attachments*/}
      <div className='md:flex'>
        <div className='xl:w-1/4 md:w-1/3'>
          <div className='md:ml-4'>
            <div className='mb-1 text-[#414651] text-sm font-semibold'>
              Curriculum <span className='text=[#7F56D9]'>*</span>
            </div>
            <div className='text-[#535862] text-sm'>
              Upload & edit your curriculum documents here.{' '}
              {MAX_CURRICULUM_DOCUMENTS}{' '}
              {pluralize('document', MAX_CURRICULUM_DOCUMENTS)} maximum
            </div>
          </div>
        </div>

        <div className='xl:w-2/4 md:w-2/3'>
          <div>
            <AttachmentsUploadAndDisplay
              attachableParams={{}}
              attachments={attachments}
              // showAttachmentsChildren={true}
              // showUploaderChildren={true}
              onAddAttachment={handleAttachmentAdded}
              onRemoveAttachment={handleAttachmentDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ClassroomSettings.defaultProps = {
  onBack: () => {},
};

ClassroomSettings.propTypes = {
  onBack: PropTypes.func,
};

export default ClassroomSettings;
