import * as microsoftTeams from '@microsoft/teams-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StorageService from 'services/StorageService';
import PulsatingLoader from 'components/pulsating-loader/PulsatingLoader';
import ErrorPage from 'components/error-page/ErrorPage';

const TeamsAuthenticate = () => {
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channel_id');
  const tenantId = searchParams.get('tenant_id');

  const navigate = useNavigate();

  const getTeamsToken = async () => {
    return await microsoftTeams.authentication.getAuthToken();
  };

  const setup = async () => {
    try {
      setError('');
      await microsoftTeams.app.initialize();
      const teamsToken = await getTeamsToken();
      StorageService.set('token', teamsToken);
      navigate(`/teams/${tenantId}/channels/${channelId}`);
    } catch (error) {
      console.error(error);
      StorageService.remove('token');
      setError('Error occurred during authentication:');
    }
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <div>
      {error ? (
        <div className='h-screen flex justify-center items-center'>
          <div className='w-full'>
            <ErrorPage
              title='Unexpected error occured'
              subtitle='Error occurred during authentication'
              tryAgainText='Try Again'
              onTryAgain={setup}
            />
          </div>
        </div>
      ) : (
        <PulsatingLoader text='Authenticating...' />
      )}
    </div>
  );
};

export default TeamsAuthenticate;
