import applicationConfig from '../../applicationConfig';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import ErrorPage from 'components/error-page/ErrorPage';
import { useErrorHandler } from 'hooks/';
import { clearAppToken } from 'apis/';

const AdminConsent = () => {
  const [consentError, setConsentError] = useState('');
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get('tenant_id');
  const channelId = searchParams.get('channel_id');
  const clientId = applicationConfig.microsoftTeams.clientId;
  const redirectUri = `${applicationConfig.appBaseUrl}/adminconsent/redirect`;
  const scopes = 'https://graph.microsoft.com/.default';

  const errorHandler = useErrorHandler();

  const handleConsent = () => {
    const url = `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=12345`;
    const consentWindow = window.open(url, 'consentTab');

    window.addEventListener(
      'message',
      async (event) => {
        try {
          if (event.origin !== applicationConfig.appBaseUrl) {
            return; // Security check
          }
          if (event.data === 'consent-granted') {
            await clearAppToken(channelId);
            consentWindow.close();
            window.location.href = `/teams/${tenantId}/channels/${channelId}`;
          } else if (event.data === 'consent-denied') {
            setConsentError(
              'An error occurred while granting consent. Please try again or reach out to your administrator for assistance.',
            );
          }
        } catch (e) {
          errorHandler(e, () => {
            setConsentError(
              'An error occurred while granting consent. Please try again or reach out to your administrator for assistance.',
            );
          });
        }
      },
      false,
    );
  };

  return (
    <>
      <div className='h-screen flex justify-center items-center'>
        <div className='w-full'>
          <ErrorPage
            title={consentError ? 'Permission Error' : 'Grant Admin Consent'}
            subtitle={
              consentError
                ? consentError
                : 'We need extra permissions for this app to function properly.'
            }
            tryAgainText={consentError ? 'Try Again' : 'Grant Permissions'}
            onTryAgain={handleConsent}
          />
        </div>
      </div>
    </>
  );
};

export default AdminConsent;
