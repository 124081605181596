import { createSlice } from '@reduxjs/toolkit';

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState: {
    channelId: null,
    tenantId: null,
    subject: null,
    teacherRoles: [],
    onboardingComplete: null,
    attachments: [],
    members: [],
  },
  reducers: {
    setClassroomDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setClassroomDetails } = classroomSlice.actions;

export default classroomSlice.reducer;
