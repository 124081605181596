import { useAssignments, useClassroom } from 'store/selectors';
import { useToast } from '@gonurture/design-system';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addAssignments } from 'store/reducers/assignments-reducer';
import { assignmentsList } from 'apis/';
import AssignmentListLoader from '../../shared/AssignmentListLoader';
import EmptyPage from 'components/empty-page/EmptyPage';
import ErrorPage from 'components/error-page/ErrorPage';
import AssignmentCard from './AssignmentCard';

const AssignmentList = () => {
  const [assignmentsLoading, setAssignmentsLoading] = useState(true);
  const [assignmentsError, setAssignmentsError] = useState('');
  const navigate = useNavigate();
  const { channelId } = useClassroom();
  const assignments = useAssignments();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const navigateToAssignmentTypePage = () => {
    navigate('assignment/type');
  };

  const fetchAssignments = async () => {
    try {
      setAssignmentsLoading(true);
      setAssignmentsError('');
      const { works: assignments } = await assignmentsList(channelId);
      dispatch(addAssignments(assignments));
      setAssignmentsLoading(false);
    } catch (e) {
      setAssignmentsLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignments',
          variant: 'error',
        });
        setAssignmentsError(e.message);
      });
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  return (
    <>
      {assignmentsError && (
        <ErrorPage
          title='Error occurred when fetching assignments'
          subtitle={assignmentsError}
          tryAgainText='Retry'
          onTryAgain={fetchAssignments}
        />
      )}

      {!assignmentsError && assignmentsLoading && <AssignmentListLoader />}

      {!assignmentsError && !assignmentsLoading && assignments.length > 0 && (
        <div>
          {assignments.map((assignment) => (
            <div className='mb-6' key={assignment.id}>
              <AssignmentCard key={assignment.id} assignment={assignment} />
            </div>
          ))}
        </div>
      )}

      {!assignmentsError && !assignmentsLoading && assignments.length === 0 && (
        <EmptyPage
          title='Looks like there are no assessments in this channel yet.'
          subtitle='Create a new assessment and assign it to your students.'
          tryAgainText='New Task'
          onTryAgain={navigateToAssignmentTypePage}
        />
      )}
    </>
  );
};

export default AssignmentList;
