import { createRequest } from 'services/ApiService';
import applicationConfig from '../applicationConfig';

export const addMessage = async (channelId, data) => {
  try {
    return await createRequest({
      url: `threads/messages`,
      method: 'post',
      payload: data,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMessages = async (channelId, threadId) => {
  try {
    return await createRequest({
      url: `threads/${threadId}/messages`,
      method: 'get',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const runThread = ({
  onMessage,
  channelId,
  threadId,
  query = '',
  onError = null,
}) => {
  const url = `${applicationConfig.apiBaseUrl}/threads/${threadId}/run?group_id=${channelId}&${query}`;
  const eventSource = new EventSource(url);

  eventSource.onmessage = (event) => {
    onMessage(event);
  };

  eventSource.onerror = (error) => {
    console.log('Error occurred', error);
    if (onError) onError();
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};

export const deleteThread = async (channelId, threadId) => {
  try {
    return await createRequest({
      url: `threads/${threadId}`,
      method: 'delete',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
