import User from '../../../../../components/user/User';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import { NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const NewLongTermGoalState = ({ value, onSelect }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  const values = [
    'Develop a coherent and well-structured essay writing style.',
    'Improve ability to research and analyze historical events.',
  ];

  return (
    <div>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054] mb-5'>
        Choose a new <strong>long term goal</strong> that is most important to
        you.
      </div>

      <div>
        <div className='flex flex-col space-x-0 space-y-2'>
          {values.map((v, index) => (
            <div
              key={index}
              className={`flex flex-1 items-center space-x-2 border bg-white py-4 px-2 rounded-sm cursor-pointer hover:bg-[#F2F4F7] ${
                value === v
                  ? 'bg-[#E9D7FE] border-[#7F56D9] hover:bg-[#E9D7FE]'
                  : ''
              }`}
              onClick={() => onSelect(v)}
            >
              <NurtureIcon icon='trophy' width={20} />
              <span className='font-medium pr-5'>{v}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

NewLongTermGoalState.defaultProps = {
  value: undefined,
  onSelect: () => {},
};

NewLongTermGoalState.propTypes = {
  value: PropTypes.number,
  onSelect: PropTypes.func,
};

export default NewLongTermGoalState;
