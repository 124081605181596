import { createSlice } from '@reduxjs/toolkit';

export const aiFeedbackSlice = createSlice({
  name: 'aiFeedback',
  initialState: {
    learningObjectives: null,
    grade: null,
    rubricsGrade: null,
    personalFeedback: null,
  },
  reducers: {
    setAiFeedback: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearAiFeedback: (state) => {
      state = {
        learningObjectives: null,
        grade: null,
        rubricsGrade: null,
        personalFeedback: null,
      };
      return state;
    },
  },
});

export const { setAiFeedback, clearAiFeedback } = aiFeedbackSlice.actions;

export default aiFeedbackSlice.reducer;
