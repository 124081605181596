import { createRequest } from '../services/ApiService';

export const insights = async (channelId, userId = null) => {
  try {
    return await createRequest({
      url: `insights`,
      method: 'get',
      queryParams: {
        group_id: channelId,
        ...(userId && { user_id: userId }),
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};
