import PropTypes from 'prop-types';
import User from '../../../../../components/user/User';
import { components } from 'react-select';
import { MultiSelect, NurtureIcon } from '@gonurture/design-system';

const DashboardStudentsField = ({ users, onSelect }) => {
  let options = [
    {
      label: (
        <div className='!font-normal text-xs text-[#344054]'>All Students</div>
      ),
      value: null,
    },
  ];

  const studentOptions = users.map((user) => {
    return {
      label: (
        <User user={user} textClassName='!font-normal text-xs text-[#344054]' />
      ),
      value: user.id,
    };
  });

  options = options.concat(studentOptions);

  const handleValueChanged = (selected) => {
    onSelect(selected.value);
  };

  /* eslint-disable */
  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props} className='!rounded-lg pl-2'>
        <NurtureIcon icon='grey-search' className='mr-2' />
        {children}
      </components.Control>
    );
  };
  /* eslint-enable */

  return (
    <MultiSelect
      components={{ Control }}
      classNames={{
        option: () => '',
        multiValue: () => '!bg-white !border !rounded-md',
        multiValueRemove: () =>
          'hover:!bg-white hover:!text-[#344054] !text-[#344054]',
      }}
      multi={false}
      options={options}
      onValuesChange={handleValueChanged}
    />
  );
};

DashboardStudentsField.defaultProps = {
  onSelect: () => {},
};

DashboardStudentsField.propTypes = {
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
};

export default DashboardStudentsField;
