import { createSlice } from '@reduxjs/toolkit';

export const nonGradedRubricsObjectivesSlice = createSlice({
  name: 'nonGradedRubricsObjectives',
  initialState: {
    rubricsTableDirty: false,
    objectives: [],
  },
  reducers: {
    setNonGradedRubricsObjectives: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearNonGradedRubricsObjectives: (state) => {
      state = {
        rubricsTableDirty: false,
        objectives: [],
      };
      return state;
    },
  },
});

export const {
  setNonGradedRubricsObjectives,
  clearNonGradedRubricsObjectives,
} = nonGradedRubricsObjectivesSlice.actions;

export default nonGradedRubricsObjectivesSlice.reducer;
