import { Button, NurtureIcon, StepProgress } from '@gonurture/design-system';
import { useState } from 'react';
import FeedbackDetailsHeader from './FeedbackDetailsHeader';
import LearningOutcomesState from './states/LearningOutcomesState';
import GoalsAchievedState from './states/GoalsAchievedState';
import PersonalFeedbackState from './states/PersonalFeedbackState';
import FeedbackReflectionState from './states/FeedbackReflectionState';
import NewShortTermGoalState from './states/NewShortTermGoalState';
import NewLongTermGoalState from './states/NewLongTermGoalState';
import ReflectionCommentState from './states/ReflectionCommentState';
import SummaryState from './states/SummaryState';
import GradeDisplay from './states/GradeDisplay';
import StudentReflectionState from './states/StudentReflectionState';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../store/selectors';

const FeedbackDetails = () => {
  const [activeState, setActiveState] = useState('learningOutcomes');
  const [reflectionFeeling, setReflectionFeeling] = useState(null);
  const [shortTermGoal, setShortTermGoal] = useState(null);
  const [longTermGoal, setLongTermGoal] = useState(null);
  const [reflectionComment, setReflectionComment] = useState(null);
  const [createdReflection, setCreatedreflection] = useState(null);

  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const showScrollableSummary = !!feedback?.reflection;
  const nextButtonText =
    activeState === 'reflectionComment' ? 'Finish' : 'Next';
  const showNextButton = activeState !== 'summary' && !showScrollableSummary;

  const STATES = {
    learningOutcomes: {
      text: 'Learning Outcomes',
      position: 1,
      icon: 'purple-message',
      canGoNext: true,
      render: () => (
        <LearningOutcomesState showScrollableSummary={showScrollableSummary} />
      ),
    },
    goalsAchieved: {
      text: 'Goals Achieved',
      position: 2,
      icon: 'purple-message',
      canGoNext: true,
      render: () => <GoalsAchievedState />,
    },
    personalFeedback: {
      text: 'Personal Feedback',
      position: 3,
      icon: 'purple-message',
      canGoNext: true,
      render: () => <PersonalFeedbackState />,
    },
    feedbackReflection: {
      text: 'Feedback Reflection',
      position: 4,
      icon: 'purple-smiley-face',
      canGoNext: !!reflectionFeeling,
      render: () => (
        <FeedbackReflectionState
          reflectionFeelingValue={reflectionFeeling}
          onChangeReflectionFeeling={(v) => setReflectionFeeling(v)}
        />
      ),
    },
    newShortTermGoal: {
      text: 'New Short Term Goal',
      position: 5,
      icon: 'purple-arrow-on-board',
      canGoNext: !!shortTermGoal,
      render: () => (
        <NewShortTermGoalState
          value={shortTermGoal}
          onSelect={(v) => setShortTermGoal(v)}
        />
      ),
    },
    newLongTermGoal: {
      text: 'New Long Term Goal',
      position: 6,
      icon: 'purple-arrow-on-board',
      canGoNext: !!longTermGoal,
      render: () => (
        <NewLongTermGoalState
          value={longTermGoal}
          onSelect={(v) => setLongTermGoal(v)}
        />
      ),
    },
    reflectionComment: {
      text: 'Personal Comment',
      position: 7,
      icon: 'purple-message',
      canGoNext: true,
      render: () => (
        <ReflectionCommentState
          reflectionCommentValue={reflectionComment}
          onChangeReflectionComment={(v) =>
            setReflectionComment(v.currentTarget.value)
          }
        />
      ),
    },
    summary: {
      text: 'Summary',
      position: 8,
      render: () => (
        <SummaryState
          reflectionFeeling={reflectionFeeling}
          reflectionComment={reflectionComment}
          shortTermGoal={shortTermGoal}
          longTermGoal={longTermGoal}
          onTransition={(state) => setActiveState(state)}
          onCreateReflection={(v) => setCreatedreflection(v)}
        />
      ),
    },
    gradeDisplay: {
      text: 'Grade Display',
      position: 9,
      render: () => <GradeDisplay createdReflection={createdReflection} />,
    },
  };

  const maxSteps = Object.keys(STATES).length - 1; // we are not including the grade Display state

  const activeStateConfig = STATES[activeState];

  const prevState = () => {
    const currentStatePosition = activeStateConfig.position;
    const prevStatePosition = currentStatePosition - 1;

    if (prevStatePosition > 0) {
      const prevStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === prevStatePosition,
      );
      setActiveState(prevStateKey);
    }
  };
  const nextState = () => {
    const currentStatePosition = activeStateConfig.position;
    const nextStatePosition = currentStatePosition + 1;

    if (nextStatePosition <= maxSteps) {
      const nextStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === nextStatePosition,
      );
      setActiveState(nextStateKey);
    }
  };

  return (
    <div className='mt-10'>
      <div className='flex flex-col h-screen'>
        {!showScrollableSummary && (
          <>
            <div className='mb-6'>
              <FeedbackDetailsHeader
                activeState={activeState}
                onPrev={prevState}
                activeStateConfig={activeStateConfig}
              />
            </div>

            <div className='mb-10'>
              <StepProgress
                currentStep={activeStateConfig.position}
                steps={maxSteps}
              />
            </div>

            <div className='flex-grow overflow-scroll'>
              {activeStateConfig.render()}
            </div>
          </>
        )}

        {showScrollableSummary && (
          <>
            <div className='mb-16'>
              <LearningOutcomesState
                showScrollableSummary={showScrollableSummary}
              />
            </div>
            <div className='mb-16'>
              <GoalsAchievedState />
            </div>
            <div className='mb-16'>
              <PersonalFeedbackState />
            </div>
            <div className='mb-16'>
              <StudentReflectionState />
            </div>
          </>
        )}

        {showNextButton && (
          <div className='my-8'>
            <Button
              disabled={!activeStateConfig.canGoNext}
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={nextState}
            >
              {nextButtonText}
              <NurtureIcon icon='white-arrow-right' className='ml-2' />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackDetails;
