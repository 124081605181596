import SubmissionAnimation from 'assets/images/student_submission.gif';
import { Button } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const NewSubmissionPrompt = ({ onStartSubmission, isTeacher }) => {
  return (
    <div className='border px-4 py-6 rounded-md border-[#D6BBFB]'>
      <div className='flex justify-center'>
        <img src={SubmissionAnimation} width='300px' height='300px'></img>
      </div>
      <div className='font-semibold text-xl'>Student Instructions</div>
      <div className='text-sm font-normal text-[#475467]'>
        Complete your work for this assessment outside of Nurture either on
        paper or on a document and once it is ready, submit your work as an
        attachment here.
      </div>
      <Button
        disabled={isTeacher}
        className='hover:bg-[#7F56D9] hover:opacity-90 w-full mt-10'
        color='#7F56D9'
        textColor='#FFFFFF'
        onClick={onStartSubmission}
      >
        Submit Assessment ➜
      </Button>
    </div>
  );
};

NewSubmissionPrompt.defaultProps = {
  isTeacher: false,
  onStartSubmission: () => {},
};

NewSubmissionPrompt.propTypes = {
  isTeacher: PropTypes.bool,
  onStartSubmission: PropTypes.func,
};

export default NewSubmissionPrompt;
