import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AttachmentsUploadAndDisplay from 'components/attachments-upload-and-display/AttachmentsUploadAndDisplay';
import { uniqBy } from 'lodash';
import { pluralize } from '@gonurture/design-system';

export const MAX_CURRICULUM_DOCUMENTS = 10;

const TeacherOnboardingUploadCurriculum = ({
  defaultAttachments,
  onAttachmentsUpdated,
  onAttachmentDelete,
}) => {
  const [attachments, setAttachments] = useState(defaultAttachments);

  const handleAttachmentAdd = (attachment) => {
    setAttachments((prev) => uniqBy([...prev, attachment], 'id'));
  };

  const handleJustUploadedAttachments = (attachment) => {
    onAttachmentsUpdated(uniqBy([...attachments, attachment], 'id'));
  };

  const handleJustUploadedAttachmentDeleted = (attachment) => {
    onAttachmentDelete(attachment);
  };

  const handleAttachmentDelete = (attachment) => {
    setAttachments((prev) => prev.filter((a) => a.id !== attachment.id));
  };

  useEffect(() => {
    onAttachmentsUpdated(attachments);
  }, [attachments]);

  return (
    <div>
      <div className='mb-8'>
        <div className='text-[#344054] text-xl font-semibold mb-3'>
          Finally, upload your curriculum (optional)
        </div>

        <div className='text-[#667085]'>
          {MAX_CURRICULUM_DOCUMENTS}{' '}
          {pluralize('document', MAX_CURRICULUM_DOCUMENTS)} maximum
        </div>
      </div>

      <div>
        <AttachmentsUploadAndDisplay
          attachableParams={{}}
          attachments={attachments}
          onAddAttachment={handleAttachmentAdd}
          onRemoveAttachment={handleAttachmentDelete}
          onUploadComplete={handleJustUploadedAttachments}
          onDeleteRecentlyUploaded={handleJustUploadedAttachmentDeleted}
        />
      </div>
    </div>
  );
};

TeacherOnboardingUploadCurriculum.defaultProps = {
  defaultAttachments: [],
  onAttachmentsUpdated: () => {},
  onAttachmentDelete: () => {},
};
TeacherOnboardingUploadCurriculum.propTypes = {
  defaultAttachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
  onAttachmentDelete: PropTypes.func,
};

export default TeacherOnboardingUploadCurriculum;
