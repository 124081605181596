import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import { Button, Emoji, NurtureIcon, useToast } from '@gonurture/design-system';
import { createReflection } from 'apis/';
import { useErrorHandler } from 'hooks/';
import { useState } from 'react';
import PropTypes from 'prop-types';

const SummaryState = ({
  reflectionFeeling,
  reflectionComment,
  longTermGoal,
  shortTermGoal,
  onTransition,
  onCreateReflection,
}) => {
  const [reflectionCreateLoading, setReflectionCreateLoading] = useState(false);
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const { channelId, userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const onSubmit = async () => {
    try {
      setReflectionCreateLoading(true);
      const payload = {
        feedback_id: feedback.id,
        comment: reflectionComment,
        emoji: reflectionFeeling,
      };
      const result = await createReflection(channelId, payload);
      onCreateReflection(result);
      onTransition('gradeDisplay');
      setReflectionCreateLoading(false);
      toast({ description: 'Reflection saved successfully' });
    } catch (e) {
      errorHandler(e, () => {
        setReflectionCreateLoading(false);
        toast({
          description: 'Error occurred when saving reflection',
          variant: 'destructive',
        });
      });
    }
  };

  return (
    <div>
      <div className='text-2xl mb-1'>Confirm your reflection</div>
      <p className='text-[#344054] mb-5 text-sm'>{currentAssignment?.title}</p>

      <div className='mb-5 flex flex-wrap justify-between items-center'>
        <p className='text-[#344054] flex items-center'>
          You are{' '}
          <Emoji
            className='mx-1'
            width={20}
            height={20}
            confidence={reflectionFeeling}
          />{' '}
          with your teachers feedback.
        </p>
        <div
          className='text-[#7341C6] text-sm cursor-pointer font-semibold'
          onClick={() => onTransition('feedbackReflection')}
        >
          Change
        </div>
      </div>

      <div className='border-t border-gray-300 pt-5 mb-5'>
        <div className='flex items-center mb-2'>
          <NurtureIcon icon='medal' width={20} />
          <p className='ml-2 font-semibold'>New Short Term Goal</p>
        </div>
        <div className='flex flex-wrap justify-between items-center'>
          <p className='text-[#344054] mb-2'>{shortTermGoal}</p>
          <div
            className='text-[#7341C6] text-sm cursor-pointer font-semibold'
            onClick={() => onTransition('newShortTermGoal')}
          >
            Change
          </div>
        </div>
      </div>

      <div className='border-t border-gray-300 pt-5 mb-5'>
        <div className='flex items-center mb-2'>
          <NurtureIcon icon='trophy' width={20} />
          <p className='font-semibold ml-2'>New Long Term Goal</p>
        </div>
        <div className='flex flex-wrap justify-between items-center'>
          <p className='text-[#344054] mb-2'>{longTermGoal}</p>
          <div
            className='text-[#7341C6] text-sm cursor-pointer font-semibold'
            onClick={() => onTransition('newLongTermGoal')}
          >
            Change
          </div>
        </div>
      </div>

      <div className='border-t border-gray-300 pt-5 mb-5'>
        <p className='font-semibold'>Personal Comment For Teacher</p>
        <div className='flex flex-wrap justify-between items-center'>
          <p className='text-[#344054] mb-2'>
            {reflectionComment ||
              'You did not leave any personal comment for your teacher'}
          </p>
          <div
            className='text-[#7341C6] text-sm cursor-pointer font-semibold'
            onClick={() => onTransition('reflectionComment')}
          >
            {reflectionComment ? 'Change' : 'Add +'}
          </div>
        </div>
      </div>

      <div className='bg-[#F9F5FF] p-5 rounded-lg mb-5 border border-[#D6BBFB]'>
        <p className='text-[#344054] text-sm text-center mb-5'>
          You can’t make edits later once you unlock your grade. Do you
          understand?
        </p>

        <Button
          className='bg-[#7F56D9] hover:bg-[#7F56D9] text-white w-full flex justify-center items-center mt-3 text-lg'
          withLoader={true}
          loading={reflectionCreateLoading}
          onClick={onSubmit}
        >
          Yes, Unlock my grade{' '}
          <NurtureIcon icon='white-arrow-right' className='ml-2' />
        </Button>
      </div>
    </div>
  );
};

SummaryState.propTypes = {
  onTransition: PropTypes.func.isRequired,
  reflectionFeeling: PropTypes.string.isRequired,
  reflectionComment: PropTypes.string.isRequired,
  shortTermGoal: PropTypes.string.isRequired,
  longTermGoal: PropTypes.string.isRequired,
  onCreateReflection: PropTypes.func.isRequired,
};

export default SummaryState;
