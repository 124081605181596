import { createRequest } from 'services/ApiService';
import { handleApiError } from './handle-api-error';

export const getUserProfile = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/user_profile`,
      method: 'get',
    });
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};
