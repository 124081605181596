import {
  NurtureIcon,
  ScrollArea,
  User,
  Sheet,
  useToast,
  SubmissionStatusBadge,
  StudentSubmissionCard,
  StudentReflectionCard,
  Separator,
  EngagementScoreCard,
  AverageTimeSpentOnFeedbackCard,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import {
  useAiFeedbackTeacherActions,
  useCurrentAssignment,
  useCurrentUserAssignment,
  useNonGradedRubricsObjectives,
} from 'store/selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userAssignmentShow } from 'apis/';
import { useDispatch } from 'react-redux';
import { addUserAssignments } from 'store/reducers/user-assignments-reducer';
import { useErrorHandler } from 'hooks/';
import FeedbackAiChat from './feedback-ai-chat/FeedbackAiChat';
import UserAssignmentPagination from './UserAssignmentPagination';
import UserAssignmentDropdown from './UserAssignmentDropdown';
import FeedbackForm from './feedback-form/FeedbackForm';
import { clearAiFeedback } from 'store/reducers/ai-feedback-reducer';
import { clearAiFeedbackTeacherActions } from 'store/reducers/ai-feedback-teacher-actions-reducer';
import {
  clearNonGradedRubricsObjectives,
  setNonGradedRubricsObjectives,
} from 'store/reducers/non-graded-rubrics-objectives-reducer';
import { getNotSelectedRubricsObjectives } from 'lib/rubrics-grading';

const UserAssignmentModal = ({ opened, defaultUserAssignmentId, onClose }) => {
  const [userAssignmentId, setUserAssignmentId] = useState(null);
  const [prevButtonLoading, setPrevButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const userAssignment = useCurrentUserAssignment(userAssignmentId);
  const { rubricsGrade: teacherActionRubricsGrade } =
    useAiFeedbackTeacherActions();
  const { rubricsTableDirty } = useNonGradedRubricsObjectives();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();

  const availableUserAssignments = currentAssignment?.user_works;
  const currentIndex = availableUserAssignments?.findIndex(
    (userAssignment) => userAssignment.id === userAssignmentId,
  );

  const resetStoreDetails = () => {
    dispatch(clearAiFeedback());
    dispatch(clearAiFeedbackTeacherActions());
    dispatch(clearNonGradedRubricsObjectives());
  };

  const handleClose = () => {
    if (rubricsGradedCorrectly()) {
      resetStoreDetails();
      onClose();
    }
  };

  const locate = async (direction) => {
    if (!rubricsGradedCorrectly()) return;

    const movement = { prev: -1, next: 1 };
    const buttonLoadingFunction = {
      prev: setPrevButtonLoading,
      next: setNextButtonLoading,
    };

    try {
      // No need to move to next or prev if only one submission
      if (availableUserAssignments?.length === 1) return;

      if (currentIndex === -1) return;

      let newIndex = currentIndex + movement[direction];
      if (newIndex === -1)
        newIndex = availableUserAssignments?.length - 1 || currentIndex;
      if (newIndex >= availableUserAssignments?.length) newIndex = 0;
      const newUserAssignment = availableUserAssignments[newIndex];

      buttonLoadingFunction[direction](true);

      const result = await userAssignmentShow(channelId, newUserAssignment.id);
      dispatch(addUserAssignments([result]));
      setUserAssignmentId(newUserAssignment.id);
      resetStoreDetails();
      buttonLoadingFunction[direction](false);
    } catch (e) {
      buttonLoadingFunction[direction](false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  const rubricsGradedCorrectly = () => {
    // If the teacher did not grade any rubrics, return true
    if (teacherActionRubricsGrade?.length === 0) return true;

    if (!rubricsTableDirty) return true;

    // If the teacher graded all the rubrics, return true
    if (
      teacherActionRubricsGrade?.length ===
      currentAssignment?.objectives?.length
    ) {
      return true;
    }

    const notSelectedRubricsObjectives = getNotSelectedRubricsObjectives(
      teacherActionRubricsGrade,
      currentAssignment?.objectives,
    );

    dispatch(
      setNonGradedRubricsObjectives({
        objectives: notSelectedRubricsObjectives,
      }),
    );

    toast({
      description: 'Please grade all the rubrics or unselect all the rubrics',
      variant: 'destructive',
    });

    return false;
  };

  const prev = () => locate('prev');

  const next = () => locate('next');

  useEffect(() => {
    setUserAssignmentId(defaultUserAssignmentId);
  }, [defaultUserAssignmentId]);

  return (
    <Sheet
      contentClassName='w-full 2xl:w-3/4'
      opened={opened}
      showDefaultClose={false}
    >
      <div className='flex flex-col lg:flex-row'>
        {/* Left Column - Main Content */}
        <ScrollArea className='w-full order-2 lg:order-1 lg:w-8/12 p-6 lg:border-r lg:h-screen lg:overflow-y-scroll px-2 sm:px-3'>
          <div className='flex justify-between mb-8'>
            {/*CLose Button*/}
            <div
              className='flex cursor-pointer items-center border border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 font-semibold text-sm text-[#344054] rounded-lg transition-all duration-100'
              onClick={handleClose}
            >
              Close{' '}
              <NurtureIcon icon='x-close' className='ml-1 w-[16px] h-[16px]' />
            </div>

            {/*Top Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              nextLoading={nextButtonLoading}
              prevLoading={prevButtonLoading}
              onNext={next}
              onPrev={prev}
            />
          </div>

          <div className='flex justify-between mb-8'>
            <User user={userAssignment?.user || {}} />

            <div className='flex space-x-4 items-center'>
              <SubmissionStatusBadge submission={userAssignment} />

              <UserAssignmentDropdown userAssignment={userAssignment} />
            </div>
          </div>

          {/*Metric Cards (Engagement Score, Average Time spent t review feedback)*/}
          <div className='mb-8 lg:flex lg:space-x-3'>
            <div className='lg:w-1/2 mb-3 lg:mb-0'>
              <EngagementScoreCard
                maxEngagementScore={userAssignment?.engagement_score?.max_score}
                engagementScore={
                  userAssignment?.engagement_score?.engagement_score
                }
              />
            </div>

            <div className='lg:w-1/2'>
              <AverageTimeSpentOnFeedbackCard
                averageTimeString={
                  userAssignment?.time_spent_reviewing_feedback || '?'
                }
              />
            </div>
          </div>

          {/*Student Submission*/}
          <div className='mb-8'>
            <StudentSubmissionCard
              submission={userAssignment?.submissions[0]}
            />
          </div>

          <div className='mb-8'>
            <FeedbackForm
              submission={userAssignment?.submissions[0]}
              assignment={currentAssignment}
              userAssignmentId={userAssignment?.id}
            />
          </div>

          <div className='mb-8'>
            <StudentReflectionCard
              reflection={userAssignment?.submissions[0]?.feedback?.reflection}
            />
          </div>

          <Separator className='mb-8' />

          <div className='flex items-center justify-between'>
            <User user={userAssignment?.user || {}} />

            {/*Bottom Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              nextLoading={nextButtonLoading}
              prevLoading={prevButtonLoading}
              onNext={next}
              onPrev={prev}
            />
          </div>
        </ScrollArea>

        {/* Right Column - AI Chat */}
        <ScrollArea className='w-full h-screen overflow-y-scroll order-1 lg:order-2 lg:w-4/12 lg:pl-0 px-2'>
          <FeedbackAiChat
            assignmentId={assignmentId}
            userAssignment={userAssignment}
          />
        </ScrollArea>
      </div>
    </Sheet>
  );
};

UserAssignmentModal.defaultProps = {
  opened: false,
};

UserAssignmentModal.propTypes = {
  opened: PropTypes.bool,
  defaultUserAssignmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserAssignmentModal;
