import IntercomSupport from '@gonurture/design-system/dist/assets/intercom_support.svg';
// import ClockIcon from 'assets/images/general_icons/clock.svg';
import PointerInSquare from '@gonurture/design-system/dist/assets/pointer_in_square.svg';
import EmailInSquare from '@gonurture/design-system/dist/assets/email_in_square.svg';
import YoutubeInSquare from '@gonurture/design-system/dist/assets/youtube_in_square.svg';
import MicrosoftLearnInSquare from '@gonurture/design-system/dist/assets/microsoft_learn_in_square.svg';
import WebinarInSquare from '@gonurture/design-system/dist/assets/webinar_in_square.svg';
import { Button, NurtureIcon, Separator } from '@gonurture/design-system';
import PropTypes from 'prop-types';
// import { useIntercom } from 'react-use-intercom';

const NurtureResources = ({ onBack }) => {
  const YOUTUBE_TUTORIALS =
    'https://www.youtube.com/playlist?list=PLPXrfOZRCSXwWKubNyOyK2oakly3h94Dv';
  const MICROSOFT_LEARN_COURSE =
    'https://learn.microsoft.com/en-us/training/modules/close-feedback-loop-microsoft-teams-with-nurture/';
  const WEBINAR = 'https://gonurture.com/nurture-webinars/library';

  // const { show } = useIntercom();

  const goToLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div>
      <div className='mb-8'>
        <Button outline className='mr-2 w-auto h-auto' onClick={onBack}>
          <NurtureIcon icon='black-arrow-left' />
        </Button>
      </div>

      <div className='space-y-10'>
        <div>
          <div className='text-[#344054] text-xl font-semibold'>
            Nurture Support
          </div>

          <Separator className='my-6' />

          {/* Support on Intercom */}
          <div
            className='border rounded-lg shadow-sm hover:border-gray-50'
            // onClick={show}
          >
            <div className='p-4 flex items-center space-x-6'>
              <img
                src={IntercomSupport}
                className='w-16 h-16'
                alt='Intercom Support'
              />
              <div>
                <h3 className='text-lg font-medium mb-1'>
                  Support on Intercom
                </h3>
                <p className='text-sm text-[#535862] flex items-center mb-1'>
                  <NurtureIcon className='mr-1' icon='grey-clock-rewind' />
                  <span>Typically 2-3 minute response</span>
                </p>
                <p>
                  Use Intercom to message one of the Nurture team for a fast
                  response.
                </p>
              </div>
              <img
                src={PointerInSquare}
                className='w-6 h-6 ml-auto'
                alt='Pointer Icon'
              />
            </div>
          </div>

          {/* Email Support */}
          <div className='border rounded-lg shadow-sm mt-8'>
            <div className='p-4 flex items-center space-x-6'>
              <img
                src={EmailInSquare}
                className='w-16 h-16'
                alt='Email Support'
              />
              <div>
                <h3 className='text-lg font-medium mb-1'>Email Support</h3>
                <p className='text-sm text-[#535862] flex items-center space-x- mb-1'>
                  <NurtureIcon className='mr-1' icon='grey-clock-rewind' />
                  <span>Typically 10-15 minute response</span>
                </p>
                <p>
                  Please email <strong>support@gonurture.com</strong> with any
                  questions or queries you may have. Attaching screenshots to
                  add further context to your issue will help solve it faster.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='text-[#344054] text-xl font-semibold'>
            Nurture Resources
          </div>

          <Separator className='my-6' />

          {/* Nurture Video Tutorials */}
          <div
            className='border rounded-lg shadow-sm hover:border-gray-500 cursor-pointer transition-all'
            onClick={() => goToLink(YOUTUBE_TUTORIALS)}
          >
            <div className='p-4 flex items-center space-x-6'>
              <img
                src={YoutubeInSquare}
                className='w-16 h-16'
                alt='YouTube Tutorials'
              />
              <div>
                <h3 className='text-lg font-medium'>Nurture Video Tutorials</h3>
                <p>
                  A playlist of video tutorials guiding you from installing
                  Nurture to publishing feedback on your first assessment.
                </p>
              </div>
              <img
                src={PointerInSquare}
                className='w-6 h-6 ml-auto'
                alt='Pointer Icon'
              />
            </div>
          </div>

          {/* Microsoft Learn Course */}
          <div
            className='border rounded-lg shadow-sm hover:border-gray-500 cursor-pointer transition-all mt-8'
            onClick={() => goToLink(MICROSOFT_LEARN_COURSE)}
          >
            <div className='p-4 flex items-center space-x-6'>
              <img
                src={MicrosoftLearnInSquare}
                className='w-16 h-16'
                alt='Microsoft Learn'
              />
              <div>
                <h3 className='text-lg font-medium'>Microsoft Learn Course</h3>
                <p>
                  Complete our course ‘Close the feedback loop in Microsoft
                  Teams with Nurture’ and earn credits towards your Microsoft
                  Innovative Educator Expert badge.
                </p>
              </div>
              <img
                src={PointerInSquare}
                className='w-6 h-6 ml-auto'
                alt='Pointer Icon'
              />
            </div>
          </div>

          {/* Webinars */}
          <div
            className='border rounded-lg shadow-sm hover:border-gray-500 cursor-pointer transition-all mt-8'
            onClick={() => goToLink(WEBINAR)}
          >
            <div className='p-4 flex items-center space-x-6'>
              <img src={WebinarInSquare} className='w-16 h-16' alt='Webinars' />
              <div>
                <h3 className='text-lg font-medium'>Webinars</h3>
                <p>
                  Register for future webinars and access our library of webinar
                  recordings on the Nurture website here.
                </p>
              </div>
              <img
                src={PointerInSquare}
                className='w-6 h-6 ml-auto'
                alt='Pointer Icon'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NurtureResources.defaultProps = {
  onBack: () => {},
};

NurtureResources.propTypes = {
  onBack: PropTypes.func,
};

export default NurtureResources;
