import { Button } from '@gonurture/design-system';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const GradeDisplay = ({ onClose }) => {
  const close = () => {
    onClose();
  };

  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const Animation = () => {
    return (
      <DotLottieReact
        src='https://lottie.host/ff87f335-4f8c-463e-a0f2-a13339c4a02f/VCKBnG3mwO.json'
        loop
        autoplay
      />
    );
  };

  return (
    <div>
      <div className='fixed inset-0 backdrop-blur-sm'></div>
      <div className='fixed inset-0 flex items-center justify-center z-50'>
        <div
          className='border-2 border-black rounded-lg px-4 md:px-8 py-4 pt-18 text-center mb-8 bg-white z-51'
          style={{ boxShadow: '1px 5px' }}
        >
          <div className='mb-8'>
            <Animation />
          </div>
          <div className='text-3xl text-[#101828] mb-5'>Submitted!</div>
          <p className='text-[#344054] mb-6 text-lg'>
            Check back again soon for your teachers feedback
          </p>
          <Button
            className='bg-[#7F56D9] text-white hover:bg-[#7F56D9] w-full mb-4'
            onClick={close}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

GradeDisplay.defaultProps = {
  onClose: () => {},
};

GradeDisplay.propTypes = {
  onClose: PropTypes.func,
};

export default GradeDisplay;
