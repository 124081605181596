import { useToast } from '@gonurture/design-system';
import { useParams } from 'react-router-dom';
import AssignmentForm from 'components/assignment-form/AssignmentForm';
import BackButton from 'components/back-button/BackButton';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import { useEffect, useState } from 'react';
import { assignmentShow } from 'apis/';
import { addAssignment } from 'store/reducers/assignments-reducer';
import { clearUserAssignments } from 'store/reducers/user-assignments-reducer';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';

const AssignmentEdit = () => {
  const [assignmentLoading, setAssignmentLoading] = useState(false);

  const dispatch = useDispatch();
  const { toast } = useToast();
  const { assignmentId } = useParams();
  const { channelId, tenantId } = useClassroom();
  const errorHandler = useErrorHandler();

  const [backLocation, setBackLocation] = useState(
    `/teams/${tenantId}/channels/${channelId}/assignment/${assignmentId}`,
  );

  const currentAssignment = useCurrentAssignment(assignmentId);

  const fetchAssignment = async () => {
    try {
      setAssignmentLoading(true);
      const assignment = await assignmentShow(channelId, assignmentId);
      dispatch(addAssignment(assignment));
      dispatch(clearUserAssignments()); // clear the userAssignment in the store
      setAssignmentLoading(false);
    } catch (e) {
      setAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  useEffect(() => {
    if (!currentAssignment.objectives) {
      fetchAssignment();
      setBackLocation(`/teams/${tenantId}/channels/${channelId}`);
    }
  }, [currentAssignment]);

  return (
    <>
      {assignmentLoading && <div>Loading...</div>}

      {!assignmentLoading && (
        <div>
          <div className='my-5'>
            <BackButton to={backLocation} />
          </div>

          <AssignmentForm
            context='AssignmentEdit'
            defaultAssignmentId={assignmentId}
          />
        </div>
      )}
    </>
  );
};

export default AssignmentEdit;
