import PropTypes from 'prop-types';
import { Checkbox, pluralize } from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import { useAiFeedback, useNonGradedRubricsObjectives } from 'store/selectors';
import { setAiFeedbackTeacherActions } from 'store/reducers/ai-feedback-teacher-actions-reducer';
import { useDispatch } from 'react-redux';
import { setNonGradedRubricsObjectives } from 'store/reducers/non-graded-rubrics-objectives-reducer';
import { getNotSelectedRubricsObjectives } from 'lib/rubrics-grading';
// import RubricsGradingTableModal from './RubricsGradingTableModal';

const RubricsGradingTable = ({
  assignment,
  feedback,
  // inFullscreen,
  onGraded,
  // onCloseModal,
}) => {
  const [rubricGrades, setRubricGrades] = useState([]);
  const [studentScore, setStudentScore] = useState(0);
  // const [showFullscreen, setShowFullscreen] = useState(false);

  const { rubricsGrade: aiRubricsGrade } = useAiFeedback();
  const { objectives: nonGradedRubricsObjectives } =
    useNonGradedRubricsObjectives();
  const dispatch = useDispatch();

  const rubrics = assignment?.rubrics_json || [];

  const selectedCellClassName =
    'border-[#7F56D9] border-2 bg-[#F9F5FF] hover:bg-[#F9F5FF]';

  const isCellSelected = (objectiveId, cellId) => {
    const row = rubricGrades?.find((item) => item.objective_id === objectiveId);
    if (!row) return false;

    return row.rubric_cell_id === cellId;
  };

  const handleSelect = (objectiveId, cellId, teacherAction = true) => {
    setRubricGrades((prev) => {
      const result = [...prev];

      // Get current selected for the learning objective
      const rowIndex = result.findIndex(
        (item) => item.objective_id === objectiveId,
      );

      if (rowIndex !== -1) {
        // something is currently selected

        if (result[rowIndex].rubric_cell_id === cellId) {
          // if the same cell is selected - unselect it
          result.splice(rowIndex, 1);
        } else {
          // if a different cell is selected, update the selected cell
          result[rowIndex] = { ...result[rowIndex], rubric_cell_id: cellId };
        }
      } else {
        result.push({
          objective_id: objectiveId,
          rubric_cell_id: cellId,
        });
      }

      const updatedNonGradedObjectives = getNotSelectedRubricsObjectives(
        result,
        assignment?.objectives,
      );

      if (teacherAction) {
        dispatch(
          setNonGradedRubricsObjectives({
            rubricsTableDirty: true,
            objectives: updatedNonGradedObjectives,
          }),
        );
        dispatch(setAiFeedbackTeacherActions({ rubricsGrade: result }));
      }
      // Only auto-save if there are errors on the rubrics grading table or if no rubrics are graded
      if (updatedNonGradedObjectives.length === 0 || result.length === 0) {
        onGraded({ rubric_grades: result });
      }

      return result;
    });
  };

  const isRubricsObjectiveNonGraded = (objectiveId) => {
    return (nonGradedRubricsObjectives || [])?.find(
      (item) => item.id === objectiveId,
    );
  };

  useEffect(() => {
    setRubricGrades(feedback?.rubric_grades || []);
  }, [feedback]);

  useEffect(() => {
    if (aiRubricsGrade?.length > 0) {
      for (let i = 0; i < aiRubricsGrade.length; i++) {
        handleSelect(
          aiRubricsGrade[i].objective_id,
          aiRubricsGrade[i].rubric_cell_id,
          false,
        );
      }
    }
  }, [aiRubricsGrade]);

  useEffect(() => {
    let totalStudentScore = 0;
    rubricGrades?.forEach((rubricGrade) => {
      const rubric = rubrics.find(
        (r) => r.objective_id === rubricGrade.objective_id,
      );
      const score = rubric?.columns.find(
        (c) => c.cell_id === rubricGrade.rubric_cell_id,
      ).point;

      totalStudentScore += score;
    });

    setStudentScore(totalStudentScore);
  }, [rubricGrades]);

  useEffect(() => {}, [nonGradedRubricsObjectives]);

  return (
    <>
      <div className='rounded-lg border border-[#EAECF0] shadow-sm'>
        <div className='flex justify-between flex-wrap items-start px-3 py-6'>
          <div className=''>
            {assignment?.rubrics_grade_display === 'points' && (
              <>
                <div className='font-semibold text-[#101828] text-lg mb-1'>
                  Students Grade : {studentScore}/{assignment?.max_points}
                </div>
                <div className='text-[#475467] text-sm'>
                  Points are split evenly across all learning outcomes
                </div>
              </>
            )}

            {assignment?.rubrics_grade_display === 'labels' && (
              <div className='font-semibold text-[#101828] text-lg mb-2'>
                Rubrics is graded with Labels
              </div>
            )}
          </div>

          {/*<div>*/}
          {/*  {!inFullscreen && (*/}
          {/*    <div*/}
          {/*      className='flex cursor-pointer items-center border border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 font-semibold text-sm text-[#344054] rounded-lg transition-all duration-100'*/}
          {/*      onClick={() => setShowFullscreen(true)}*/}
          {/*    >*/}
          {/*      Fullscreen{' '}*/}
          {/*      <NurtureIcon*/}
          {/*        icon='x-close'*/}
          {/*        className='ml-1 w-[16px] h-[16px]'*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}

          {/*  {inFullscreen && (*/}
          {/*    <div*/}
          {/*      className='flex cursor-pointer items-center border border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 font-semibold text-sm text-[#344054] rounded-lg transition-all duration-100'*/}
          {/*      onClick={onCloseModal}*/}
          {/*    >*/}
          {/*      Close{' '}*/}
          {/*      <NurtureIcon*/}
          {/*        icon='x-close'*/}
          {/*        className='ml-1 w-[16px] h-[16px]'*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>

        {rubrics.length > 0 && (
          <div className='overflow-x-auto'>
            <table className='min-w-full bg-white border'>
              <thead>
                <tr className='bg-[#F4F3FF] text-left border-b text-xs'>
                  <th className='p-3 border-x min-w-[200px]'>
                    Learning Outcome
                  </th>
                  {rubrics[0].columns.map((column) => (
                    <th className='min-w-[200px] p-3 border-x' key={column.id}>
                      <div className='flex justify-between align-center'>
                        <span>{column.name}</span>
                        {assignment?.rubrics_grade_display !== 'labels' && (
                          <span className='text-[#7F56D9]'>
                            {column.point} {pluralize('point', column.point)}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {rubrics.map((rubric, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${isRubricsObjectiveNonGraded(rubric.objective_id) && 'border-2 border-[#F04438]'}`}
                    >
                      <td className='p-3 border'>
                        <div className='flex items-center border-l-0'>
                          {/*<div className='flex items-center justify-center w-8 h-8 bg-[#7F56D9] rounded-full text-white'>*/}
                          {/*  {rubric.objective_id}*/}
                          {/*</div>*/}
                          <div className='flex-grow ml-2 text-sm font-semibold'>
                            <div>{rubric['objective_name']}</div>
                            {isRubricsObjectiveNonGraded(
                              rubric.objective_id,
                            ) && (
                              <div className='text-[#F04438] text-sm mt-2 font-normal'>
                                Please select a criteria
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      {rubric.columns.map((column, index) => (
                        <td
                          key={index}
                          className={`p-3 border text-sm cursor-pointer hover:bg-gray-50 ${isCellSelected(rubric.objective_id, column.cell_id) && selectedCellClassName}`}
                          onClick={() =>
                            handleSelect(rubric.objective_id, column.cell_id)
                          }
                        >
                          <div className='flex items-start'>
                            <Checkbox
                              checked={isCellSelected(
                                rubric.objective_id,
                                column.cell_id,
                              )}
                              className='data-[state=checked]:bg-[#7F56D9] data-[state=checked]:border-[#7F56D9] mt-1 rounded'
                            />
                            <span className='ml-2'>{column.value}</span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/*<RubricsGradingTableModal*/}
      {/*  modalOpened={showFullscreen}*/}
      {/*  onClose={() => setShowFullscreen(false)}*/}
      {/*  assignment={assignment}*/}
      {/*  feedback={{ rubric_grades: rubricGrades }}*/}
      {/*  onGraded={onGraded}*/}
      {/*/>*/}
    </>
  );
};

RubricsGradingTable.defaultProps = {
  inFullscreen: false,
  onGraded: () => {},
  onCloseModal: () => {},
};

RubricsGradingTable.propTypes = {
  assignment: PropTypes.object.isRequired,
  feedback: PropTypes.object.isRequired,
  // inFullscreen: PropTypes.bool,
  onGraded: PropTypes.func,
  // onCloseModal: PropTypes.func,
};

export default RubricsGradingTable;
