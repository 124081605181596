import PropTypes from 'prop-types';
import { Badge, Tooltip } from '@gonurture/design-system';

const SelectedItemBadge = ({ item, onRemove }) => {
  return (
    <Badge
      className='bg-[#F9F5FF] border-[#E9D7FE] text-xs text-[#7341C6] rounded hover:bg-[#F9F5FF] hover:border-[#E9D7FE] mb-1'
      text={
        <div className='flex space-x-2'>
          <span>{item}</span>
          <Tooltip
            trigger={
              <button className='text-[#9892F6]' onClick={() => onRemove(item)}>
                &#10005;
              </button>
            }
          >
            Remove
          </Tooltip>
        </div>
      }
    />
  );
};

SelectedItemBadge.defaultProps = {
  onRemove: () => {},
};

SelectedItemBadge.propTypes = {
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};

export default SelectedItemBadge;
