import { Skeleton } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const DashboardHeaderLoader = ({ isTeacher }) => {
  return (
    <div className='lg:flex justify-between items-center'>
      <div className='md:flex items-center mb-4 lg:mb-0'>
        <div className='mr-4 mb-4 md:mb-0 flex'>
          <Skeleton className='h-16 w-16 rounded-full' />
          {isTeacher && (
            <>
              <Skeleton className='h-16 w-16 rounded-full ml-[-15px]' />
              <Skeleton className='h-16 w-16 rounded-full ml-[-15px]' />
              <Skeleton className='h-16 w-16 rounded-full ml-[-15px]' />
            </>
          )}
        </div>

        <div>
          <div className='mb-3'>
            <Skeleton className='h-8 w-48' />
          </div>
          <div>
            <Skeleton className='h-8 w-64' />
          </div>
        </div>
      </div>

      <div className='lg:w-1/4'>
        <Skeleton className='h-8 w-full' />
      </div>
    </div>
  );
};

DashboardHeaderLoader.defaultProps = {
  isTeacher: false,
};

DashboardHeaderLoader.propTypes = {
  isTeacher: PropTypes.bool,
};

export default DashboardHeaderLoader;
