import { Outlet, useParams } from 'react-router-dom';
import StorageService from 'services/StorageService';
import isJwtExpired from 'lib/is-jwt-expired';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/reducers/user-reducer';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useClassroom, useUser } from 'store/selectors';

export const AuthenticateUser = ({ params }) => {
  const token = StorageService.get('token');
  const isTokenExpired = isJwtExpired(token); // returns true if there is no token

  StorageService.set('channelId', params.channelId);
  StorageService.set('tenantId', params.tenantId);

  if (isTokenExpired) {
    window.location.href = `/authenticate?channel_id=${params.channelId}&tenant_id=${params.tenantId}`;
    return;
  }

  return true;
};

const Protected = () => {
  const dispatch = useDispatch();
  const { channelId, tenantId } = useParams();
  const { channelId: channelIdFromStore, tenantId: tenantIdFromStore } =
    useClassroom();
  const userFromStore = useUser();
  const user = StorageService.getObject('user');

  if (!userFromStore) dispatch(setUser(user));
  if (!channelIdFromStore || !tenantIdFromStore) {
    dispatch(
      setClassroomDetails({
        channelId: channelId,
        tenantId: tenantId,
        ...{
          ...user?.classroom_details,
          onboardingComplete: user?.classroom_details?.onboarding_complete,
          teacherRoles: user?.classroom_details?.teacher_roles,
        },
      }),
    );
  }

  return (
    <div className='px-5'>
      <Outlet />
    </div>
  );
};

export default Protected;
