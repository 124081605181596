import { createSlice } from '@reduxjs/toolkit';

export const aiFeedbackTeacherActionsSlice = createSlice({
  name: 'aiFeedbackTeacherActions',
  initialState: {
    learningObjectives: null,
    grade: null,
    rubricsGrade: null,
    personalFeedback: null,
  },
  reducers: {
    setAiFeedbackTeacherActions: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearAiFeedbackTeacherActions: (state) => {
      state = {
        learningObjectives: null,
        grade: null,
        rubricsGrade: null,
        personalFeedback: null,
      };
      return state;
    },
  },
});

export const { setAiFeedbackTeacherActions, clearAiFeedbackTeacherActions } =
  aiFeedbackTeacherActionsSlice.actions;

export default aiFeedbackTeacherActionsSlice.reducer;
