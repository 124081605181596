import { DashboardHeader } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const NAVS = {
  dashboard: 'dashboard',
  tasks: 'tasks',
  classroomSettings: 'classroomSettings',
  support: 'support',
};

const TeacherHomeNav = ({ activeTab, onTabChange }) => {
  const navigate = useNavigate();

  const navigateToAssignmentTypePage = () => {
    navigate('assignment/type');
  };

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const primaryTabs = [
    {
      name: 'Dashboard',
      value: NAVS.dashboard,
      icon: 'grey-wand',
      activeIcon: 'colorful-wand',
      onClick: () => handleTabClick(NAVS.dashboard),
    },
    {
      name: 'Tasks',
      value: NAVS.tasks,
      icon: 'grey-check-in-circle-overlap',
      activeIcon: 'colorful-check-in-circle',
      onClick: () => handleTabClick(NAVS.tasks),
    },
  ];

  const secondaryAction = {
    name: 'New Task',
    onClick: navigateToAssignmentTypePage,
  };

  const secondaryTabs = [
    {
      icon: 'grey-settings',
      name: 'Classroom Settings',
      onClick: () => handleTabClick(NAVS.classroomSettings),
      value: 'settings',
    },
    {
      icon: 'grey-question-mark-in-circle',
      name: 'Support',
      onClick: () => handleTabClick(NAVS.support),
      value: 'support',
    },
  ];

  return (
    <DashboardHeader
      primaryTabs={primaryTabs}
      activeTab={activeTab}
      secondaryTabs={secondaryTabs}
      secondaryAction={secondaryAction}
    />
  );
};

TeacherHomeNav.defaultProps = {
  onTabChange: () => {},
};

TeacherHomeNav.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(NAVS)).isRequired,
  onTabChange: PropTypes.func,
};

export default TeacherHomeNav;
