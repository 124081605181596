import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import User from '../../../../../components/user/User';

const GoalsAchievedState = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  return (
    <div>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054] mb-5'>
        Well done, you achieved <strong>1</strong> of your{' '}
        <strong>long term</strong> goals and <strong>2</strong> of your{' '}
        <strong>short term</strong> goals on this assessment!
      </div>

      <div
        className='border-2 border-black rounded-lg p-4 pt-52 text-center mb-8'
        style={{ boxShadow: '1px 5px' }}
      >
        <div className='text-2xl text-[#7F56D9]'>Long Term Goal Achieved!</div>
        <p className='text-[#344054] mt-2'>
          Enhance overall historical understanding and analytical skills.
        </p>
      </div>

      <div
        className='border-2 border-black rounded-lg p-4 pt-52 text-center mb-8'
        style={{ boxShadow: '1px 5px' }}
      >
        <div className='text-2xl text-[#7F56D9]'>Short Term Goal Achieved!</div>
        <p className='text-[#344054] mt-2'>
          Understanding the causes, major battles, and outcomes of World War
          One.
        </p>
      </div>

      <div
        className='border-2 border-black rounded-lg p-4 pt-52 text-center mb-8'
        style={{ boxShadow: '1px 5px' }}
      >
        <div className='text-2xl text-[#7F56D9]'>Short Term Goal Achieved!</div>
        <p className='text-[#344054] mt-2'>
          Memorize a set of key historical facts related to World War One.
        </p>
      </div>
    </div>
  );
};

export default GoalsAchievedState;
