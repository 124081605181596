import PropTypes from 'prop-types';
import Attachments from 'components/attachments/Attachments';
import { Separator } from '@gonurture/design-system';

const TeacherOnboardingSummary = ({
  selectedSubject,
  attachments,
  selectedRoles,
  onEditSubject,
  onEditRoles,
}) => {
  return (
    <div>
      <div className='text-[#344054] text-xl font-semibold mb-8'>
        Confirm your changes
      </div>

      <div className='mb-8'>
        <div className='text-[#344054] font-medium text-lg'>Subject</div>
        <Separator className='my-3' />
        <div className='flex justify-between flex-wrap items-center'>
          <div className='text-sm font-medium text-[#344054] py-2 px-4 rounded border border-[#D0D5DD]'>
            {selectedSubject}
          </div>
          <div
            className='text-sm text-[#7341C6] cursor-pointer font-semibold flex-grow text-end'
            onClick={onEditSubject}
          >
            Change
          </div>
        </div>
      </div>

      <div className='mb-8'>
        <div className='text-[#344054] font-medium text-lg'>
          Classroom Roles
        </div>
        <Separator className='my-3' />
        <div className='flex justify-between flex-wrap items-center'>
          <div className='flex flex-wrap'>
            {selectedRoles.map((role) => (
              <div
                key={role}
                className='text-sm font-medium text-[#344054] py-2 px-4 mr-2 rounded border border-[#D0D5DD] mb-1'
              >
                {role}
              </div>
            ))}
          </div>
          <div
            className='text-sm text-[#7341C6] cursor-pointer font-semibold flex-grow text-end'
            onClick={onEditRoles}
          >
            Change
          </div>
        </div>
      </div>

      <div>
        <div className='text-[#344054] font-medium mb-2 text-lg'>
          Curriculum Documents
        </div>
        <Separator className='my-3' />
        {attachments.length > 0 ? (
          <div>
            <Attachments attachments={attachments} canDelete={false} />
          </div>
        ) : (
          <div className='text-sm text-[#344054]'>
            No Curriculum Document Uploaded
          </div>
        )}
      </div>
    </div>
  );
};

TeacherOnboardingSummary.defaultProps = {
  selectedSubject: undefined,
  selectedRoles: [],
  attachments: [],
  onEditSubject: () => {},
  onEditRoles: () => {},
};

TeacherOnboardingSummary.propTypes = {
  selectedSubject: PropTypes.string,
  selectedRoles: PropTypes.array,
  attachments: PropTypes.array,
  onEditSubject: PropTypes.func,
  onEditRoles: PropTypes.func,
};

export default TeacherOnboardingSummary;
