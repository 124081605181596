export const mdTableToRubricsFormat = (mdString) => {
  let result = [];
  let headers;
  let hasSeparators;
  mdString
    .replace(/```|```md|```markdown/g, '')
    .split('\n')
    .filter(Boolean)
    .forEach((line, lineNumber) => {
      if (lineNumber === 0) {
        // Headers
        headers = line
          .split('|')
          .filter(Boolean)
          .map((item) => item.trim());
      } else {
        if (lineNumber === 1) {
          // Skip the | ----- | ---- |
          hasSeparators =
            lineNumber === 1 && /\|*\s*[-]+\s*(\|\s*[-]+\s*)+\|*/.test(line);
          if (hasSeparators) return;
        }

        const values = line
          .split('|')
          .filter(Boolean)
          .map((item) => item.trim());
        let resultLine = {};

        values.forEach((cell, index) => {
          resultLine[headers[index]] = cell;
        });

        result.push(resultLine);
      }
    });

  // At this point the result is in this form
  // [
  //  {'Learning Outcome': 'Lo 1', 'pass': 'pass criteria 1', 'fail': 'fail criteria 1', 'average': 'average criteria 1'},
  //  {'Learning Outcome': 'Lo 2', 'pass': 'pass criteria 2', 'fail': 'fail criteria 2', 'average': 'average criteria 2'},
  //  {'Learning Outcome': 'Lo 3', 'pass': 'pass criteria 3', 'fail': 'fail criteria 3', 'average': 'average criteria 3'},
  // ]

  result = jsonToRubricsFormat(result);

  return result;
};

/**
 * converts data from this format
 *  [
 *    {'Learning Outcome': 'Lo 1', 'pass': 'pass criteria 1', 'fail': 'fail criteria 1', 'average': 'average criteria 1'},
 *    {'Learning Outcome': 'Lo 2', 'pass': 'pass criteria 2', 'fail': 'fail criteria 2', 'average': 'average criteria 2'},
 *    {'Learning Outcome': 'Lo 3', 'pass': 'pass criteria 3', 'fail': 'fail criteria 3', 'average': 'average criteria 3'},
 *   ]
 *
 *   to
 *
 *   [
 *    {
 *      'Learning Outcome': 'Lo 1',
 *      'columns': [
 *        {name: 'pass', order: 0, value: 'pass criteria 1'},
 *        {name: 'fail', order: 1, value: 'pass criteria 2'},
 *        {name: 'pass', order: 2, value: 'pass criteria 2'}
 *      ]
 *    }
 *    {...}
 *   ]
 *
 * @returns []
 * @param hashFormat
 */
export const jsonToRubricsFormat = (hashFormat) => {
  return [...hashFormat].map((item, index) => {
    const data = { index: index };

    // The prompt is forced to make the first column 'Learning Outcome'
    data['objective_name'] =
      item['Learning Outcome'] || item['Learning Outcomes'];
    delete item['Learning Outcome'];
    delete item['Learning Outcomes'];

    let columns = [];
    let columnCount = 0;
    for (const key in item) {
      const hash = {};
      hash['name'] = key;
      hash['value'] = item[key];
      hash['order'] = columnCount;
      hash['point'] = columnCount + 1;

      columns.push(hash);
      columnCount += 1;
    }

    //check if the last column name is U then
    // make the last column the first column
    if (columns[columns.length - 1].name === 'U') {
      columns.unshift(columns.pop());

      // reset the order
      columns = columns.map((column, index) => {
        column['order'] = index;
        column['point'] = index + 1;
        return column;
      });
    }

    data['columns'] = columns;

    return data;
  });
};
