import PropTypes from 'prop-types';
import { Skeleton } from '@gonurture/design-system';

const AssignmentListLoader = ({ assignmentCount }) => {
  const fakeAssignments = new Array(assignmentCount).fill(0);

  return (
    <div>
      {fakeAssignments.length > 0 && (
        <>
          {fakeAssignments.map((assignment, index) => (
            <div key={index} className='mb-6'>
              <div className='h-30 p-3 md:flex items-center border rounded-lg shadow-sm'>
                <div className='flex items-center flex-grow mb-3 md:mb-0'>
                  <Skeleton className='w-[50px] h-[50px] mr-3' />
                  <div className='flex-grow mr-3'>
                    <Skeleton className='h-4 mb-2 w-3/4' />
                    <Skeleton className='h-4' />
                  </div>
                </div>

                <div className='flex space-x-3 justify-between items-center'>
                  <Skeleton className='w-16 h-12' />
                  <Skeleton className='w-16 h-12' />
                  <Skeleton className='w-16 h-12' />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

AssignmentListLoader.defaultProps = {
  assignmentCount: 6,
};

AssignmentListLoader.propTypes = {
  assignmentCount: PropTypes.number,
};

export default AssignmentListLoader;
