import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import msalInstance from 'lib/msal';
import StorageService from 'services/StorageService';
import PulsatingLoader from 'components/pulsating-loader/PulsatingLoader';
import ErrorPage from 'components/error-page/ErrorPage';

const BrowserAuthenticate = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [searchParams] = useSearchParams();
  const [authError, setAuthError] = useState(false);
  const channelId = searchParams.get('channel_id');
  const tenantId = searchParams.get('tenant_id');

  const buttonRef = useRef();

  const signIn = () => {
    setAuthenticated(true);
    setIsAuthenticating(true);
    msalInstance
      .loginPopup({ scopes: ['openid', 'profile', 'User.Read'] })
      .then(async (loginResponse) => {
        setAuthError(false);
        StorageService.set('token', loginResponse.idToken);
        setIsAuthenticating(false);
        window.location.href = `/teams/${tenantId}/channels/${channelId}`;
      })
      .catch((error) => {
        setAuthError(true);
        setIsAuthenticating(false);
        StorageService.remove('token');
        console.error('Error during login:', error);
      });
  };

  useEffect(() => {
    if (!authenticated) {
      setTimeout(() => {
        buttonRef?.current?.click();
      }, 2000);
    }
  }, []);

  return (
    <>
      {!authenticated && (
        <button
          ref={buttonRef}
          onClick={signIn}
          style={{ visibility: 'hidden' }}
        >
          Browser Authentication
        </button>
      )}
      {isAuthenticating ? (
        <PulsatingLoader text='Authenticating...' />
      ) : (
        <>
          {authError && (
            <div className='h-screen flex justify-center items-center'>
              <div className='w-full'>
                <ErrorPage
                  title='Unexpected error occured'
                  subtitle='Error occurred during authentication'
                  tryAgainText='Try Again'
                  onTryAgain={signIn}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BrowserAuthenticate;
