import {
  AlertDialog,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  NurtureIcon,
  useToast,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useClassroom } from 'store/selectors';
import { useErrorHandler } from 'hooks/';
import { deleteAssignment } from 'apis/';
import { useDispatch } from 'react-redux';
import { removeAssignment } from 'store/reducers/assignments-reducer';
import { useNavigate } from 'react-router-dom';

const AssignmentOptionsDropdown = ({ assignment }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { channelId, tenantId } = useClassroom();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const deletedAssignment = await deleteAssignment(
        channelId,
        assignment.id,
      );
      setDeleteLoading(false);
      setShowDeleteConfirmation(false);
      dispatch(removeAssignment(deletedAssignment));
      toast({
        title: 'Success',
        description: 'Assignment deleted successfully',
      });
    } catch (e) {
      errorHandler(e, () => {
        setDeleteLoading(false);
        toast({
          title: 'Error',
          description: 'Error occurred while deleting assignment',
          variant: 'destructive',
        });
      });
    }
  };

  const handleEdit = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${assignment.id}/edit`,
    );
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <NurtureIcon icon='grey-ellipsis-vertical' />
        </DropdownMenuTrigger>

        <DropdownMenuContent className=''>
          <DropdownMenuGroup>
            <DropdownMenuItem
              className='font-normal cursor-pointer'
              onClick={handleEdit}
            >
              <div>Edit</div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className='font-normal cursor-pointer'
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <div>Delete</div>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog
        title='Delete?'
        subtitle='Are you sure you wish to delete this assignment?'
        opened={showDeleteConfirmation}
        loading={deleteLoading}
        cancelButtonClassName=''
        actionButtonClassName='bg-red-500 hover:bg-red-600'
        onAction={handleDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
    </div>
  );
};

AssignmentOptionsDropdown.defaultProps = {
  assignment: {},
};

AssignmentOptionsDropdown.propTypes = {
  assignment: PropTypes.object,
};

export default AssignmentOptionsDropdown;
