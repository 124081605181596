import PropTypes from 'prop-types';
import PageWithIllustration from '../page-with-illustration/PageWithIllustration';
import ErrorIllustration from '@gonurture/design-system/dist/assets/error-illustration.svg';

const ErrorPage = ({ title, subtitle, tryAgainText, onTryAgain }) => {
  return (
    <PageWithIllustration
      illustration={ErrorIllustration}
      title={title}
      subtitle={subtitle}
      buttonText={tryAgainText}
      onAction={onTryAgain}
    />
  );
};

ErrorPage.defaultProps = {
  title: '',
  subtitle: '',
  tryAgainText: '',
  onTryAgain: () => {},
};
ErrorPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tryAgainText: PropTypes.string,
  onTryAgain: PropTypes.func,
};

export default ErrorPage;
