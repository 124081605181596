import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AttachmentsUploadAndDisplay from 'components/attachments-upload-and-display/AttachmentsUploadAndDisplay';
import { uniqBy } from 'lodash';

const StudentSubmissionUpload = ({
  submission,
  defaultAttachments,
  onAttachmentsUpdated,
  onAttachmentDelete,
}) => {
  const [attachments, setAttachments] = useState(defaultAttachments);

  const handleAttachmentAdd = (attachment) => {
    setAttachments((prev) => uniqBy([...prev, attachment], 'id'));
  };

  const handleJustUploadedAttachments = (attachment) => {
    onAttachmentsUpdated(uniqBy([...attachments, attachment], 'id'));
  };

  const handleJustUploadedAttachmentDeleted = (attachment) => {
    onAttachmentDelete(attachment);
  };

  const handleAttachmentDelete = (attachment) => {
    setAttachments((prev) => prev.filter((a) => a.id !== attachment.id));
  };

  useEffect(() => {
    onAttachmentsUpdated(attachments);
  }, [attachments]);

  return (
    <div>
      <div className='text-[#344054] text-xl font-semibold mb-8 '>
        First, upload your work for your teacher to review
      </div>

      <div>
        <AttachmentsUploadAndDisplay
          attachableParams={{ submission_id: submission.id }}
          attachments={attachments}
          onAddAttachment={handleAttachmentAdd}
          onRemoveAttachment={handleAttachmentDelete}
          onUploadComplete={handleJustUploadedAttachments}
          onDeleteRecentlyUploaded={handleJustUploadedAttachmentDeleted}
        />
      </div>
    </div>
  );
};

StudentSubmissionUpload.defaultProps = {
  submission: {},
  defaultAttachments: [],
  onAttachmentsUpdated: () => {},
  onAttachmentDelete: () => {},
};
StudentSubmissionUpload.propTypes = {
  submission: PropTypes.object,
  defaultAttachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
  onAttachmentDelete: PropTypes.func,
};

export default StudentSubmissionUpload;
