import { createSlice } from '@reduxjs/toolkit';

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: null,
  reducers: {
    setAnalytics: (state, action) => {
      state = action.payload;
      return state;
    },
    clearAnalytics: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setAnalytics, clearAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;
