import StorageService from 'services/StorageService';

export const convertMessageToChatFormat = (message) => {
  const result = {};
  const user = StorageService.getObject('user');

  result['contents'] = message.contents;
  result['created_at'] = message.created_at;

  if (message['role'] === 'user') {
    result['sender'] = {
      name: user.display_name,
      avatar: user.avatar_url,
    };
  }

  return result;
};
