import StorageService from 'services/StorageService';
import AssignmentList from './assignment-list/AssignmentList';
import TeacherHomeNav, { NAVS } from './TeacherHomeNav';
import { useState } from 'react';
import ClassroomSettings from './ClassroomSettings';
import NurtureResources from './NurtureResources';
import greeting from '../shared/greetings';
import Dashboard from '../shared/dashboard/Dashboard';

const Home = () => {
  const [activeTab, setActiveTab] = useState(NAVS.tasks);

  const user = StorageService.getObject('user');

  const showHeader =
    activeTab !== NAVS.classroomSettings && activeTab !== NAVS.support;

  return (
    <div className={showHeader ? 'py-8' : 'py-2'}>
      {showHeader && (
        <>
          <div className='mb-8 text-2xl mt-10 md:mt-0'>
            &#128075; {greeting()} {user.display_name.split()[0]}
          </div>

          <TeacherHomeNav activeTab={activeTab} onTabChange={setActiveTab} />
        </>
      )}

      {activeTab === NAVS.tasks && (
        <div className='mt-10'>
          <AssignmentList />
        </div>
      )}

      {activeTab === NAVS.dashboard && (
        <div className='mt-10'>
          <Dashboard />
        </div>
      )}

      {activeTab === NAVS.classroomSettings && (
        <div className='mt-10'>
          <ClassroomSettings onBack={() => setActiveTab(NAVS.tasks)} />
        </div>
      )}

      {activeTab === NAVS.support && (
        <div className='mt-10'>
          <NurtureResources onBack={() => setActiveTab(NAVS.tasks)} />
        </div>
      )}
    </div>
  );
};

export default Home;
