import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const TeacherOnboardingHeader = ({
  activeState,
  onPrev,
  activeStateConfig,
}) => {
  return (
    <div className='flex justify-between items-center'>
      {activeState !== 'prompt' && (
        <Button outline className='mr-2 w-auto h-auto' onClick={onPrev}>
          <NurtureIcon icon='black-arrow-left' />
        </Button>
      )}

      {!['prompt'].includes(activeState) && (
        <div className='flex justify-end items-center flex-grow'>
          <div>
            <div className='flex'>
              <NurtureIcon icon='purple-file-check' />
              <div className='font-semibold text-[#344054] ml-2'>
                {activeStateConfig.text}
              </div>
            </div>
            <div className='text-xs'>Setup Nurture for this Classroom</div>
          </div>
        </div>
      )}
    </div>
  );
};

TeacherOnboardingHeader.propTypes = {
  activeState: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  activeStateConfig: PropTypes.object.isRequired,
};

export default TeacherOnboardingHeader;
