import { TextArea } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const StudentSubmissionPersonalComment = ({
  personalComment,
  onPersonalCommentChanged,
}) => {
  const SUGGESTIONS = [
    'The most important thing I learned was...',
    'The way I learned was...',
    'What I found difficult was...',
    'What I enjoyed the most was...',
    'What helped me when something got tricky was...',
    'Some things I did to help learning include...',
    'Why do I think learning this skill / content is important...',
    'What can I do now that I could not do last week/month/year...',
  ];

  const handlePersonalCommentChange = (e) => {
    onPersonalCommentChanged(e.target.value);
  };

  return (
    <div>
      <div className='text-[#344054] text-xl font-semibold mb-8 '>
        Final Step, Do you have any personal comment to add for your teacher?
      </div>

      <div className='mb-4'>
        <TextArea
          rows={8}
          value={personalComment}
          onChange={handlePersonalCommentChange}
          placeholder='Tell your teacher how long it took you or where you think you could have done better.'
        />
      </div>

      <div className='text-[#344054]'>
        <div className='text-sm mb-3'>
          Some suggestions to get you thinking and help your teacher give you
          better feedback :
        </div>

        <ul className='list-disc list-inside'>
          {SUGGESTIONS.map((suggestion, index) => (
            <li key={index} className='text-sm mb-1'>
              {suggestion}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

StudentSubmissionPersonalComment.defaultProps = {
  personalComment: '',
  onPersonalCommentChanged: () => {},
};

StudentSubmissionPersonalComment.propTypes = {
  personalComment: PropTypes.string,
  onPersonalCommentChanged: PropTypes.func,
};

export default StudentSubmissionPersonalComment;
