import { Input } from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import { SUBJECTS } from './constants';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import SelectedItemBadge from './components/selected-item-badge/SelectedItemBadge';

const TeacherOnboardingSelectSubject = ({ selectedSubject, onSelect }) => {
  const [subjectsToDisplay, setSubjectsToDisplay] = useState([]);

  const selectedSubjectClassNames = 'bg-[#F4EBFF] border-[#7F56D9]';
  const baseSubjectClassNames = 'border-[#D0D5DD] hover:bg-gray-50';

  const handleInputChange = (e) => {
    const query = e.target.value;
    search(query);
  };

  const reArrange = (subjects) => {
    if (!selectedSubject) {
      return sortBy(subjects, ['name']);
    }

    // Separate the selected subject and others
    const selected = subjects.filter(
      (subject) => subject.name === selectedSubject,
    );
    const others = subjects.filter(
      (subject) => subject.name !== selectedSubject,
    );

    // Sort the others alphabetically
    const sortedOthers = sortBy(others, ['name']);

    // Combine selected subject at the top with the sorted others
    return [...selected, ...sortedOthers];
  };

  const search = (query) => {
    if (!query) return setSubjectsToDisplay(() => reArrange(SUBJECTS));

    const lowerCaseQuery = query.toLowerCase();
    const filteredSubjects = SUBJECTS.filter((subject) =>
      subject.name.toLowerCase().startsWith(lowerCaseQuery),
    );

    setSubjectsToDisplay(() => reArrange(filteredSubjects));
  };

  const selectSubject = (subject) => {
    onSelect(subject.name);
  };

  const removeSelectedSubject = () => {
    onSelect(null);
  };

  useEffect(() => {
    setSubjectsToDisplay(() => reArrange(SUBJECTS));
  }, [selectedSubject]);

  return (
    <div>
      <div className='text-[#344054] text-xl font-semibold mb-8'>
        What subject is this class?
      </div>

      <div className='mb-8'>
        <Input placeholder='Search' onChange={handleInputChange} />
      </div>

      {selectedSubject && (
        <div className='mb-3'>
          <SelectedItemBadge
            item={selectedSubject}
            onRemove={removeSelectedSubject}
          />
        </div>
      )}

      <div className='md:flex md:flex-wrap'>
        {subjectsToDisplay.map((subject, index) => (
          <div key={index} className='w-full md:w-1/2 mb-3'>
            <div
              className={`${index % 2 === 0 ? 'md:mr-1' : 'md:ml-1'} ${selectedSubject === subject.name ? selectedSubjectClassNames : baseSubjectClassNames} text-sm font-medium text-[#344054] p-2 rounded border cursor-pointer flex`}
              onClick={() => selectSubject(subject)}
            >
              <div>{subject.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TeacherOnboardingSelectSubject.defaultProps = {
  selectedSubject: null,
  onSelect: () => {},
};

TeacherOnboardingSelectSubject.propTypes = {
  selectedSubject: PropTypes.string,
  onSelect: PropTypes.func,
};

export default TeacherOnboardingSelectSubject;
