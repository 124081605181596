import PropTypes from 'prop-types';

const DashboardStudentNames = ({ users, showCount }) => {
  const usersToShow = users.slice(0, showCount);
  const remainingUsersCount = users.length - usersToShow.length;

  const firstNames = usersToShow.map(
    (user) => user?.display_name.split(' ')[0],
  );

  return (
    <div className='text-sm text-[#667085]'>
      {users.length > 1 && (
        <>
          {firstNames.join(', ')}

          {remainingUsersCount > 0 && `, +${remainingUsersCount} more`}
        </>
      )}

      {users.length === 1 && <>{users[0]?.email}</>}
    </div>
  );
};

DashboardStudentNames.defaultProps = {
  showCount: 4,
};

DashboardStudentNames.propTypes = {
  users: PropTypes.array.isRequired,
  showCount: PropTypes.number,
};

export default DashboardStudentNames;
