import { Button, NurtureIcon, Skeleton } from '@gonurture/design-system';

const AssignmentShowLoader = () => {
  return (
    <div className='mt-5'>
      <div className='mb-8'>
        <div className='flex cursor-pointer'>
          <Skeleton className='mr-2 w-[200px] h-[20px]' />
        </div>
      </div>

      <div className='mb-8'>
        <Skeleton className='w-full h-[100px]' />
      </div>

      <div className='mb-8'>
        <div className='lg:flex'>
          <div className='lg:w-6/12 lg:mr-3 mb-3 lg:mb-0'>
            <Skeleton className='w-full h-[100px] lg:h-[200px]' />
          </div>

          <div className='lg:w-6/12'>
            <div className='mb-3'>
              <Skeleton className='w-full h-[100px] lg:h-[93px]' />
            </div>

            <div>
              <Skeleton className='w-full h-[100px] lg:h-[93px]' />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-8'>
        <Skeleton className='w-full h-[70px]' />
      </div>

      <div className='mb-8'>
        <div className='rounded-lg border border-[#EAECF0] shadow-sm'>
          <div className='flex justify-between items-center px-3 py-6'>
            <div className='font-semibold text-[#101828] text-lg'>
              <Skeleton className='mr-2 w-[200px] h-[20px]' />
            </div>

            <Button className='border border-[#D0D5DD] hover:border-[#D0D5DD] bg-white hover:bg-white'>
              <span className='hidden sm:inline-flex text-sm text-[#344054] font-semibold'>
                <Skeleton className='mr-2 w-[200px] h-[20px]' />
              </span>
              <NurtureIcon icon='black-download' className='sm:ml-2' />
            </Button>
          </div>

          <div className='overflow-x-auto'>
            <table className='min-w-full bg-white border'>
              <thead>
                <tr className='bg-[#F9FAFB] text-left border-b'>
                  <th className='p-3 min-w-[120px] border-x text-sm text-[#475467] font-semibold cursor-pointer'>
                    <div className='flex'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </div>
                  </th>
                  <th className='p-3 min-w-[120px] border-x text-sm text-[#475467] font-semibold cursor-pointer'>
                    <div className='flex'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </div>
                  </th>
                  <th className='p-3 min-w-[200px] sm:min-w-[300px] border-x text-sm text-[#475467] font-semibold'>
                    <Skeleton className='mr-2 w-[200px] h-[20px]' />
                  </th>
                  <th className='p-3 min-w-[100px] border-x text-sm text-[#475467] font-semibold cursor-pointer'>
                    <Skeleton className='mr-2 w-[200px] h-[20px]' />
                  </th>
                  <th className='p-3 min-w-[150px] border-x text-sm text-[#475467] font-semibold'>
                    <Skeleton className='mr-2 w-[200px] h-[20px]' />
                  </th>
                  <th className='p-3 min-w-[100px] border-x text-sm text-[#475467] font-semibold cursor-pointer'>
                    <div className='flex'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </div>
                  </th>
                  <th className='p-3 w-[50px] text-sm text-[#475467] font-semibold'></th>
                </tr>
              </thead>

              <tbody>
                {[1, 2, 3, 4, 5, 6].map((userWork) => (
                  <tr key={userWork}>
                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <div className='text-sm text-[#101828]'>
                        <Skeleton className='mr-2 w-[200px] h-[20px]' />
                      </div>
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <div className='text-sm text-[#101828]'>
                        <Skeleton className='mr-2 w-[200px] h-[20px]' />
                      </div>
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <Skeleton className='mr-2 w-[200px] h-[20px]' />
                    </td>

                    <td className='px-3 py-5 border-b border-[#EAECF0]'>
                      <div
                        className={`cursor-pointer hover:bg-gray-100 rounded-full flex justify-center items-center w-[30px] h-[30px] transition-all duration-200`}
                      >
                        <Skeleton className='mr-2 w-[200px] h-[20px]' />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='mb-8'>
        <div className='rounded-lg border border-[#EAECF0] shadow-sm p-1 sm:p-3'>
          {[1, 2, 3]?.map((objective, index) => (
            <div
              key={index}
              className={`py-3 md:py-5 px-1 sm:px-4 md:px-5 ${index < 2 ? 'border-b' : ''}`}
            >
              <div
                className={`flex items-center space-x-1 sm:space-x-2`}
                key={objective}
              >
                <Skeleton className='flex items-center justify-center w-8 h-8 bg-[#7F56D9] rounded-full' />

                <Skeleton className='mr-2 w-[300px] h-[20px]' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignmentShowLoader;
