import { DashboardHeader } from '@gonurture/design-system';
import PropTypes from 'prop-types';

export const NAVS = {
  dashboard: 'dashboard',
  tasks: 'tasks',
};

const StudentHomeNav = ({ activeTab, onTabChange }) => {
  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const primaryTabs = [
    {
      name: 'Dashboard',
      value: NAVS.dashboard,
      icon: 'grey-wand',
      activeIcon: 'colorful-wand',
      onClick: () => handleTabClick(NAVS.dashboard),
    },
    {
      name: 'Tasks',
      value: NAVS.tasks,
      icon: 'grey-check-in-circle-overlap',
      activeIcon: 'colorful-check-in-circle',
      onClick: () => handleTabClick(NAVS.tasks),
    },
  ];

  return <DashboardHeader primaryTabs={primaryTabs} activeTab={activeTab} />;
};

StudentHomeNav.defaultProps = {
  onTabChange: () => {},
};

StudentHomeNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func,
};

export default StudentHomeNav;
