import { createRequest } from 'services/ApiService';

export const assignmentShow = async (channelId, assignmentId) => {
  try {
    return await createRequest({
      url: `works/${assignmentId}`,
      method: 'get',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const assignmentsList = async (channelId) => {
  try {
    return await createRequest({
      url: `works`,
      method: 'get',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createAssignment = async (channelId, gradeDisplay) => {
  try {
    return await createRequest({
      url: 'works',
      method: 'post',
      queryParams: {
        group_id: channelId,
      },
      payload: {
        grade_display: `grade_display_${gradeDisplay}`,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAssignment = async (channelId, assignmentId, data) => {
  try {
    return await createRequest({
      url: `works/${assignmentId}`,
      method: 'patch',
      payload: data,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const publishFeedback = async (channelId, assignmentId) => {
  try {
    return await createRequest({
      url: `works/${assignmentId}/publish_feedback`,
      method: 'post',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAssignment = async (channelId, assignmentId) => {
  try {
    return await createRequest({
      url: `works/${assignmentId}`,
      method: 'delete',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
