import { useState } from 'react';
import TeacherOnboardingPrompt from './TeacherOnboardingPrompt';
import TeacherOnboardingHeader from './TeacherOnboardingHeader';
import {
  Button,
  NurtureIcon,
  StepProgress,
  useToast,
} from '@gonurture/design-system';
import TeacherOnboardingSelectSubject from './TeacherOnboardingSelectSubject';
import TeacherOnboardingUploadCurriculum from './TeacherOnboardingUploadCurriculum';
import TeacherOnboardingSummary from './TeacherOnboardingSummary';
import { useErrorHandler } from 'hooks/';
import { useParams } from 'react-router-dom';
import { updateChannel } from 'apis/';
import { useDispatch } from 'react-redux';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useClassroom } from 'store/selectors';
import TeacherOnboardingTeacherRole from './TeacherOnboardingTeacherRole';

const TeacherOnboardingForm = () => {
  const [activeState, setActiveState] = useState('prompt');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const { channelId } = useParams();

  const { attachments: reduxClassroomAttachments } = useClassroom();
  const [curriculumBoundaryAttachments, setCurriculumBoundaryAttachments] =
    useState(reduxClassroomAttachments);

  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const showNextButton = activeState !== 'prompt' && activeState !== 'summary';

  const STATES = {
    prompt: {
      position: 1,
      render: () => <TeacherOnboardingPrompt onNext={nextState} />,
    },
    subject: {
      position: 2,
      text: 'Select Subject',
      canGoNext: selectedSubject,
      render: () => (
        <TeacherOnboardingSelectSubject
          selectedSubject={selectedSubject}
          onSelect={setSelectedSubject}
        />
      ),
    },
    teacherRole: {
      position: 3,
      text: 'Teacher Role',
      canGoNext: selectedRoles.length > 0,
      render: () => (
        <TeacherOnboardingTeacherRole
          selectedRoles={selectedRoles}
          onSelect={(role) => setSelectedRoles((prev) => [...prev, role])}
          onRemoveSelectedRole={(role) => {
            setSelectedRoles((prev) => prev.filter((r) => r !== role));
          }}
        />
      ),
    },
    curriculumBoundary: {
      position: 4,
      text: 'Upload Curriculum',
      canGoNext: curriculumBoundaryAttachments.length > 0,
      render: () => (
        <TeacherOnboardingUploadCurriculum
          defaultAttachments={curriculumBoundaryAttachments}
          onAttachmentsUpdated={setCurriculumBoundaryAttachments}
          onAttachmentDelete={(attachment) => {
            setCurriculumBoundaryAttachments((prev) =>
              prev.filter((a) => a.id !== attachment.id),
            );
          }}
        />
      ),
    },
    summary: {
      position: 5,
      text: 'Summary',
      render: () => (
        <TeacherOnboardingSummary
          selectedSubject={selectedSubject}
          selectedRoles={selectedRoles}
          attachments={curriculumBoundaryAttachments}
          onEditSubject={() => setActiveState('subject')}
          onEditRoles={() => setActiveState('teacherRole')}
        />
      ),
    },
  };

  const maxSteps = Object.keys(STATES).length;

  const activeStateConfig = STATES[activeState];

  const prevState = () => {
    const currentStatePosition = activeStateConfig.position;
    const prevStatePosition = currentStatePosition - 1;

    if (prevStatePosition > 0) {
      const prevStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === prevStatePosition,
      );
      setActiveState(prevStateKey);
    }
  };
  const nextState = () => {
    const currentStatePosition = activeStateConfig.position;
    const nextStatePosition = currentStatePosition + 1;

    if (nextStatePosition <= maxSteps) {
      const nextStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === nextStatePosition,
      );
      setActiveState(nextStateKey);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmissionLoading(true);
      const data = { subject: selectedSubject, teacher_roles: selectedRoles };
      const response = await updateChannel(channelId, data);
      const {
        subject,
        attachments,
        onboarding_complete: onboardingComplete,
        teacher_roles: teacherRoles,
      } = response;
      dispatch(
        setClassroomDetails({
          subject,
          attachments,
          onboardingComplete,
          teacherRoles,
        }),
      );
      toast({
        description: 'Your onboarding has been completed.',
      });
    } catch (error) {
      errorHandler(error, () => {
        setSubmissionLoading(false);
        toast({
          description: 'Something went wrong. Please try again.',
          variant: 'destructive',
        });
      });
    }
  };

  return (
    <div className='flex'>
      <div className='flex flex-col h-screen mx-auto w-full md:w-3/4 xl:w-1/2'>
        {activeState !== 'prompt' && (
          <div className='mt-10'>
            <div className='mb-6'>
              <TeacherOnboardingHeader
                activeState={activeState}
                onPrev={prevState}
                activeStateConfig={activeStateConfig}
              />
            </div>

            <div className='mb-10'>
              <StepProgress
                currentStep={activeStateConfig.position - 1}
                steps={maxSteps - 1}
              />
            </div>
          </div>
        )}

        <div className='flex-grow overflow-scroll'>
          {activeStateConfig.render()}
        </div>

        {showNextButton && (
          <div className='my-8'>
            <Button
              disabled={!activeStateConfig.canGoNext}
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={nextState}
            >
              Next
              <NurtureIcon icon='white-arrow-right' className='ml-2' />
            </Button>
          </div>
        )}

        {activeState === 'curriculumBoundary' && (
          <div className='mt-[-20px]'>
            <div
              className='cursor-pointer justify-center flex w-full text-sm text-[#475467] font-semibold mb-4'
              onClick={nextState}
            >
              Skip this step
              <NurtureIcon icon='black-arrow-right' className='ml-2' />
            </div>
          </div>
        )}

        {activeState === 'summary' && (
          <div className='my-8'>
            <Button
              loading={submissionLoading}
              withLoader={true}
              loaderColor='#ffffff'
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={handleSubmit}
            >
              Finish
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherOnboardingForm;
