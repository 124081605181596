import { useEffect, useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import { MultiSelect, NurtureIcon, useToast } from '@gonurture/design-system';
import { useClassroom } from 'store/selectors';
import { bulkAssign, bulkUnAssign, getMembers } from 'apis/';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import User from '../user/User';
import { components } from 'react-select';
import PropTypes from 'prop-types';

const StudentsAssignField = ({
  currentAssignment,
  triggerAssign,
  onAssign,
  onError,
}) => {
  const [fetchingMembers, setFetchingMembers] = useState(false);
  const [error, setError] = useState(''); // eslint-disable-line
  const [assignables, setAssignables] = useState([]);
  const [unassignables, setUnassignables] = useState([]);
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState([]);

  const errorHandler = useErrorHandler();

  const dispatch = useDispatch();
  const { toast } = useToast();
  const { channelId, students } = useClassroom();

  const AllStudentsOption = {
    label: (
      <div className='text-[#344054] text-xs font-normal'>All students</div>
    ),
    value: 'all',
  };

  const fetchMembers = async () => {
    try {
      setFetchingMembers(true);
      setError('');
      const members = await getMembers(channelId);
      dispatch(setClassroomDetails({ members }));
      setMultiSelectOptions(() => {
        const initialValue = [AllStudentsOption];
        const studentsOptions = students.map((student) => {
          return {
            label: (
              <User
                user={student}
                textClassName='!font-normal text-xs text-[#344054]'
              />
            ),
            value: student.id,
          };
        });

        return [...initialValue, ...studentsOptions];
      });
      setFetchingMembers(false);
    } catch (e) {
      setFetchingMembers(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching students',
          variant: 'error',
        });
        setError('Error occurred when fetching students');
      });
    }
  };

  const initialize = async () => {
    await fetchMembers();
    setAssignables(() => {
      return currentAssignment?.user_works?.map(
        (user_work) => user_work.user.id,
      );
    });
  };

  const isAssigned = (studentId) => {
    return currentAssignment?.user_works?.find(
      (user_work) => user_work?.user?.id === studentId,
    );
  };

  const handleValueChanged = async (values) => {
    // Check if "All Students" is selected
    const isAllStudentsSelected = values.some((v) => v.value === 'all');

    if (isAllStudentsSelected && values[values.length - 1].value === 'all') {
      // If "All Students" is selected, only keep this option in state
      setMultiSelectValue([{ value: 'all', label: 'All Students' }]);
      setAssignables(() => students.map((student) => student.id));
    } else {
      // Otherwise, update state with selected students only (excluding "All Students")
      const assignedStudents = values.filter((v) => v.value !== 'all');
      setMultiSelectValue(() => assignedStudents);
      setAssignables(() => assignedStudents.map((v) => v.value));
      setUnassignables(() => {
        return multiSelectOptions
          .filter(
            (student) =>
              !assignedStudents.includes(student) && isAssigned(student.value),
          )
          .map((student) => student.value);
      });
    }
  };

  // eslint-disable-next-line
  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props} className='!rounded-lg pl-2'>
        <NurtureIcon icon='black-search' className='mr-2' />
        {children}
      </components.Control>
    );
  };

  const handleAssign = async () => {
    try {
      const assignPayload = {
        work_id: currentAssignment.id,
        user_ids: assignables,
      };
      await bulkAssign(channelId, assignPayload);

      if (unassignables.length > 0) {
        const unassignPayload = {
          work_id: currentAssignment.id,
          user_ids: unassignables,
        };
        await bulkUnAssign(channelId, unassignPayload);
      }
      onAssign();
    } catch (e) {
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when assigning students',
          variant: 'error',
        });
        onError();
      });
    }
  };

  useEffect(() => {
    if (triggerAssign > 0) {
      handleAssign();
    }
  }, [triggerAssign]);

  useEffect(() => {
    initialize();
    setMultiSelectValue(() => [AllStudentsOption]);
  }, []);

  useEffect(() => {
    if (multiSelectOptions.length > 1) {
      const assignedStudentIds =
        currentAssignment?.user_works?.map((user_work) => user_work.user.id) ||
        [];

      let result = multiSelectOptions.filter((option) =>
        assignedStudentIds.includes(option.value),
      );

      result = result.length > 0 ? result : [AllStudentsOption];

      setMultiSelectValue(() => result);
      setAssignables((prev) => {
        return result[0].value === 'all'
          ? students.map((student) => student.id)
          : prev;
      });
    }
  }, [multiSelectOptions]);

  return (
    <MultiSelect
      components={{ Control }}
      classNames={{
        option: () => '',
        multiValue: () => '!bg-white !border !rounded-md',
        multiValueRemove: () =>
          'hover:!bg-white hover:!text-[#344054] !text-[#344054]',
      }}
      options={multiSelectOptions}
      loading={fetchingMembers}
      onValuesChange={handleValueChanged}
      closeMenuOnSelect={false}
      value={multiSelectValue}
    />
  );
};

StudentsAssignField.propTypes = {
  currentAssignment: PropTypes.object.isRequired,
  onAssign: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  triggerAssign: PropTypes.number.isRequired,
};

export default StudentsAssignField;
