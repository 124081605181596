import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  NurtureIcon,
  StepProgress,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import StudentSubmissionHeader from './StudentSubmissionHeader';
import PropTypes from 'prop-types';
import StudentSubmissionUpload from './StudentSubmissionUpload';
import StudentSubmissionConfidence from './StudentSubmissionConfidence';
import StudentSubmissionPersonalComment from './StudentSubmissionPersonalComment';
import StudentSubmissionSummary from './StudentSubmissionSummary';
import { submissionUpdate } from 'apis/';
import { addUserAssignmentSubmission } from 'store/reducers/user-assignments-reducer';

const StudentSubmission = ({ userAssignment, onClose }) => {
  const [activeState, setActiveState] = useState('submissionAttachments');
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const { channelId } = useParams();

  const submission = userAssignment?.submissions?.[0];
  const initialSubmissionAttachments = submission?.attachments || [];
  const [submissionAttachments, setSubmissionAttachments] = useState(
    initialSubmissionAttachments,
  );
  const [confidence, setConfidence] = useState(
    submission?.confidence_level_emoji,
  );
  const [personalComment, setPersonalComment] = useState(submission?.comment);

  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const showNextButton =
    activeState === 'submissionAttachments' || activeState === 'confidence';

  const STATES = {
    submissionAttachments: {
      position: 1,
      render: () => (
        <StudentSubmissionUpload
          submission={submission}
          defaultAttachments={submissionAttachments}
          onAttachmentsUpdated={setSubmissionAttachments}
          onAttachmentDelete={(attachment) => {
            setSubmissionAttachments((prev) =>
              prev.filter((a) => a.id !== attachment.id),
            );
          }}
        />
      ),
      canGoNext: submissionAttachments.length > 0,
    },
    confidence: {
      position: 2,
      render: () => (
        <StudentSubmissionConfidence
          selectedConfidence={confidence}
          onSelectConfidence={setConfidence}
        />
      ),
      canGoNext: !!confidence,
    },
    personalComment: {
      position: 3,
      render: () => (
        <StudentSubmissionPersonalComment
          personalComment={personalComment}
          onPersonalCommentChanged={setPersonalComment}
        />
      ),
      canGoNext: true,
    },
    summary: {
      position: 4,
      render: () => (
        <StudentSubmissionSummary
          attachments={submissionAttachments}
          confidence={confidence}
          personalComment={personalComment}
          onEditAttachments={() => setActiveState('submissionAttachments')}
          onEditConfidence={() => setActiveState('confidence')}
          onEditPersonalComment={() => setActiveState('personalComment')}
        />
      ),
      canGoNext: true,
    },
  };

  const maxSteps = Object.keys(STATES).length;

  const activeStateConfig = STATES[activeState];

  const prevState = () => {
    if (activeState === 'submissionAttachments') {
      return onClose();
    }

    const currentStatePosition = activeStateConfig.position;
    const prevStatePosition = currentStatePosition - 1;

    if (prevStatePosition > 0) {
      const prevStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === prevStatePosition,
      );
      setActiveState(prevStateKey);
    }
  };
  const nextState = () => {
    const currentStatePosition = activeStateConfig.position;
    const nextStatePosition = currentStatePosition + 1;

    if (nextStatePosition <= maxSteps) {
      const nextStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === nextStatePosition,
      );
      setActiveState(nextStateKey);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmissionLoading(true);
      const payload = {
        user_work_id: userAssignment?.id,
        comment: personalComment,
        confidence_level_emoji: confidence,
      };
      const result = await submissionUpdate(channelId, submission?.id, payload);
      console.log(result);
      dispatch(
        addUserAssignmentSubmission({
          id: userAssignment?.id,
          submission: result,
        }),
      );
      setSubmissionLoading(true);
      onClose(true);
    } catch (e) {
      errorHandler(e, () => {
        setSubmissionLoading(false);
        toast({
          description: 'Error occurred when making submission',
          variant: 'destructive',
        });
      });
    }
  };

  return (
    <div className='flex'>
      <div className='flex flex-col h-screen mx-auto w-full md:w-3/4 xl:w-1/2'>
        <div className='my-10'>
          <div className=''>
            <StudentSubmissionHeader
              activeState={activeState}
              assessmentName={userAssignment?.work?.title}
              onPrev={prevState}
            />
          </div>

          {activeState !== 'summary' && (
            <div className='mt-6'>
              <StepProgress
                currentStep={activeStateConfig.position}
                steps={maxSteps - 1}
              />
            </div>
          )}
        </div>

        <div className='flex-grow overflow-scroll'>
          {activeStateConfig.render()}
        </div>

        {showNextButton && (
          <div className='my-8'>
            <Button
              disabled={!activeStateConfig.canGoNext}
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={nextState}
            >
              Next
              <NurtureIcon icon='white-arrow-right' className='ml-2' />
            </Button>
          </div>
        )}

        {activeState === 'submissionAttachments' && (
          <div className='mt-[-20px]'>
            <div
              className='cursor-pointer justify-center flex w-full text-sm text-[#475467] font-semibold mb-4'
              onClick={nextState}
            >
              Skip this step
              <NurtureIcon icon='black-arrow-right' className='ml-2' />
            </div>
          </div>
        )}

        {activeState === 'personalComment' && (
          <div className='my-8'>
            <Button
              disabled={!activeStateConfig.canGoNext}
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={nextState}
            >
              Confirm Submission
              <NurtureIcon icon='white-arrow-right' className='ml-2' />
            </Button>
          </div>
        )}

        {activeState === 'summary' && (
          <div className='border border-[#D6BBFB] bg-[#F9F5FF] rounded-lg py-4 px-2 my-8'>
            <div className='text-center text-[#344054] mb-4'>
              You can only edit this submission until your teacher has published
              your feedback. <br />
              Do you understand?
            </div>

            <Button
              className='border-[#3CCB7F] py-6 bg-[#3CCB7F] !text-white hover:border-[#3CCB7F] hover:bg-[#3CCB7F] font-semibold text-lg w-full'
              withLoader={true}
              loading={submissionLoading}
              loaderText='Submiting...'
              onClick={handleSubmit}
            >
              Yes, Submit assessment
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

StudentSubmission.defaultProps = {
  onClose: () => {},
};

StudentSubmission.propTypes = {
  userAssignment: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default StudentSubmission;
