import { NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const PulsatingLoader = ({ icon, text }) => {
  return (
    <div className='h-screen flex justify-center items-center'>
      <div>
        <div className='w-32 h-32 flex flex-col justify-center items-center'>
          <NurtureIcon
            icon={icon}
            className='w-20 h-20 rounded-full animate-pulseOpacity'
          />

          {text && (
            <div className='animate-pulseOpacity text-[#344054]'>{text}</div>
          )}
        </div>
      </div>
    </div>
  );
};

PulsatingLoader.defaultProps = {
  icon: 'nurture-logo',
  text: undefined,
};
PulsatingLoader.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default PulsatingLoader;
