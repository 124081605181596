import applicationConfig from '../applicationConfig';
import axios from 'axios';
import StorageService from './StorageService';

const instance = axios.create({
  baseURL: applicationConfig.apiBaseUrl,
});

// Add a request interceptor
instance.interceptors.request.use(
  (request) => {
    const userToken = StorageService.get('token');

    request.headers.Authorization = `Bearer ${userToken}`;

    return request;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error?.response?.status === 401) {
      const channelId = StorageService.get('channelId');
      const tenantId = StorageService.get('tenantId');

      window.location.href = `/authenticate?channel_id=${channelId}&tenant_id=${tenantId}`;
      throw error;
    }

    if (error?.response) {
      return Promise.reject(error);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      error.message =
        'This request is taking too long, please check your network';
      throw error;
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  },
);

export const createRequest = (config) => {
  const composedConfig = composeRequestConfig(config);
  return instance(composedConfig);
};

const composeRequestConfig = (config) => {
  const {
    method = 'get',
    payload,
    queryParams: params,
    headers,
    onUploadProgress,
    ...rest
  } = config;

  const requestConfig = { method, ...rest };
  if (payload) requestConfig.data = payload;

  if (params) requestConfig.params = params;

  if (headers) requestConfig.headers = headers;

  if (onUploadProgress) requestConfig.onUploadProgress = onUploadProgress;

  return requestConfig;
};
