import PropTypes from 'prop-types';
import { Avatar } from '@gonurture/design-system';

const DashboardAvatars = ({ users, showCount }) => {
  const usersToShow = users.slice(0, showCount);
  const remainingUsersCount = users.length - usersToShow.length;

  return (
    <div className='flex'>
      {usersToShow.map((user, index) => (
        <Avatar
          className={index > 0 && 'ml-[-15px]'}
          src={user.avatar_url}
          key={user.id}
          size='xl'
        />
      ))}

      {remainingUsersCount > 0 && (
        <div className='ml-[-15px] text-[#ffffff] rounded-full bg-[#3b516c] font-semibold flex justify-center items-center w-12 h-12 z-[10] text-lg'>
          +{remainingUsersCount}
        </div>
      )}
    </div>
  );
};

DashboardAvatars.defaultProps = {
  showCount: 4,
};

DashboardAvatars.propTypes = {
  users: PropTypes.array.isRequired,
  showCount: PropTypes.number,
};

export default DashboardAvatars;
