import PropTypes from 'prop-types';
import { Input, Label } from '@gonurture/design-system';

const RubricsCriteriaLabelsCount = ({ value, error, onChange }) => {
  const increment = () => {
    onChange((value || 0) + 1);
  };

  const decrement = () => {
    onChange((value || 1) - 1);
  };

  return (
    <div className='mt-4'>
      <Label>No. of criteria labels</Label>
      <div className='flex space-x-1'>
        <div
          className='border border-[#EAECF0] cursor-pointer rounded-lg flex justify-center items-center py-1 px-4'
          onClick={decrement}
        >
          -
        </div>
        <Input
          type='number'
          className='w-[80px] h-100'
          value={value}
          onChange={onChange}
        />
        <div
          className='border border-[#EAECF0] cursor-pointer rounded-lg flex justify-center items-center py-1 px-4'
          onClick={increment}
        >
          +
        </div>
      </div>
      {error && <p className='text-sm text-destructive'>{error}</p>}
    </div>
  );
};

RubricsCriteriaLabelsCount.defaultProps = {
  value: undefined,
  error: '',
  onChange: () => {},
};

RubricsCriteriaLabelsCount.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default RubricsCriteriaLabelsCount;
