import FileUploader from '../attachments/FileUploader';
import Attachments from '../attachments/Attachments';
import { useEffect, useState } from 'react';
import { useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const AttachmentsUploadAndDisplay = ({
  attachments,
  attachableParams,
  showUploaderChildren,
  showAttachmentsChildren,
  buttonView,
  buttonViewContent,
  buttonViewClassName,
  onAddAttachment,
  onRemoveAttachment,
  onUpdateAttachment,
  onUploadComplete,
  onDeleteRecentlyUploaded,
}) => {
  const [justUploadedAttachments, setJustUploadedAttachments] = useState([]);
  const { toast } = useToast();

  const handleDeletedJustUploadedAttachment = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.filter((a) => a.id !== attachment.id),
    );

    onDeleteRecentlyUploaded(attachment);
  };

  const handleJustUploadedAttachmentUpdate = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.map((a) => (a.id === attachment.id ? attachment : a)),
    );
  };

  const handleAttachmentReset = () => {
    justUploadedAttachments.forEach((attachment) => {
      onAddAttachment(attachment);
    });

    setJustUploadedAttachments(() => []);
  };

  const handleAttachmentDelete = (attachment) => {
    onRemoveAttachment(attachment);
    toast({
      title: 'Success!',
      description: 'Attachment deleted successfully',
      variant: 'success',
    });
  };

  const handleAttachmentUpdate = (attachment) => {
    onUpdateAttachment(attachment);
  };

  const handleAttachmentUpload = (attachment) => {
    setJustUploadedAttachments((prev) => [...prev, attachment]);

    onUploadComplete(attachment);
  };

  useEffect(() => {
    return () => {
      handleAttachmentReset();
    };
  }, []);

  return (
    <div>
      <FileUploader
        attachableParams={attachableParams}
        onUpload={handleAttachmentUpload}
        showChildren={showUploaderChildren}
        buttonView={buttonView}
        buttonViewContent={buttonViewContent}
        buttonViewClassName={buttonViewClassName}
        onReset={handleAttachmentReset}
        onDelete={handleDeletedJustUploadedAttachment}
        onUpdate={handleJustUploadedAttachmentUpdate}
      />
      <div className='mt-3'>
        <Attachments
          showChildren={showAttachmentsChildren}
          attachments={attachments}
          onDelete={handleAttachmentDelete}
          onUpdate={handleAttachmentUpdate}
        />
      </div>
    </div>
  );
};

AttachmentsUploadAndDisplay.defaultProps = {
  attachments: [],
  showUploaderChildren: false,
  showAttachmentsChildren: false,
  buttonView: false,
  buttonViewContent: undefined,
  buttonViewClassName: undefined,
  onAddAttachment: () => {},
  onRemoveAttachment: () => {},
  onUpdateAttachment: () => {},
  onUploadComplete: () => {},
  onDeleteRecentlyUploaded: () => {},
};

AttachmentsUploadAndDisplay.propTypes = {
  attachments: PropTypes.array,
  attachableParams: PropTypes.object.isRequired,
  showUploaderChildren: PropTypes.bool,
  showAttachmentsChildren: PropTypes.bool,
  buttonView: PropTypes.bool,
  buttonViewContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonViewClassName: PropTypes.string,
  onAddAttachment: PropTypes.func,
  onRemoveAttachment: PropTypes.func,
  onUpdateAttachment: PropTypes.func,
  onUploadComplete: PropTypes.func,
  onDeleteRecentlyUploaded: PropTypes.func,
};

export default AttachmentsUploadAndDisplay;
