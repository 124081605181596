import Attachment from './Attachment';
import PropTypes from 'prop-types';
import AttachmentMetadata from './AttachmentMetadata';
import { useState } from 'react';
import { AlertDialog, useToast } from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { removeAttachment, userActivity } from 'apis/';
import { useClassroom, useUser } from 'store/selectors';

const Attachments = ({
  attachments,
  canDelete,
  showChildren,
  onDelete,
  onUpdate,
  ...props
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { channelId } = useClassroom();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const user = useUser();

  const handleDelete = async (attachmentId) => {
    try {
      setDeleteLoading(true);
      const removedAttachment = await removeAttachment(channelId, attachmentId);
      setDeleteLoading(false);
      setShowDeleteConfirmation(false);
      onDelete(removedAttachment);
    } catch (e) {
      errorHandler(e, () => {
        setDeleteLoading(false);
        toast({
          title: 'Error',
          description: 'Error occurred while deleting attachment',
          variant: 'destructive',
        });
      });
    }
  };

  const handleAttachmentActivity = async (attachment, event) => {
    try {
      const data = {
        relatable_type: 'Attachment',
        relatable_id: attachment.id,
        event: event,
        user_type: user.role,
      };

      await userActivity(channelId, data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleView = (attachment) => {
    handleAttachmentActivity(attachment, 'viewed');
  };

  const handleDownload = (attachment) => {
    handleAttachmentActivity(attachment, 'downloaded');
  };

  return (
    <div {...props}>
      {attachments?.map((attachment) => (
        <>
          <div className='mb-2' key={attachment.id}>
            <Attachment
              canDelete={canDelete}
              attachment={attachment}
              onDelete={() => setShowDeleteConfirmation(true)}
              onView={() => handleView(attachment)}
              onDownload={() => handleDownload(attachment)}
            >
              {showChildren && (
                <AttachmentMetadata
                  attachmentId={attachment.id}
                  defaultDescription={attachment?.metadata?.description}
                  defaultPurpose={attachment?.metadata?.purpose}
                  onUpdate={onUpdate}
                />
              )}
            </Attachment>
          </div>
          <AlertDialog
            title='Delete?'
            subtitle='Are you sure you wish to delete this attachment?'
            opened={showDeleteConfirmation}
            loading={deleteLoading}
            cancelButtonClassName=''
            actionButtonClassName='bg-red-500 hover:bg-red-600'
            onAction={() => handleDelete(attachment.id)}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        </>
      ))}
    </div>
  );
};

Attachments.defaultProps = {
  canDelete: true,
  showChildren: false,
  onDelete: () => {},
  onUpdate: () => {},
};

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  canDelete: PropTypes.bool,
  showChildren: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default Attachments;
