import { BaseFile } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const Attachment = ({
  attachment,
  canDelete,
  canDownload,
  onView,
  onDownload,
  children,
  ...props
}) => {
  return (
    <BaseFile
      filename={attachment.filename}
      filesize={attachment.filesize}
      filetype={attachment.filetype}
      viewUrl={attachment.view_url}
      downloadUrl={attachment.view_url}
      canDelete={canDelete}
      canDownload={canDownload}
      onView={onView}
      onDownload={onDownload}
      {...props}
    >
      {children}
    </BaseFile>
  );
};

Attachment.defaultProps = {
  canDelete: true,
  canDownload: true,
  children: null,
  onView: () => {},
  onDownload: () => {},
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    filename: PropTypes.string,
    filesize: PropTypes.string,
    filetype: PropTypes.string,
    view_url: PropTypes.string,
  }).isRequired,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  onView: PropTypes.func,
  onDownload: PropTypes.func,
  children: PropTypes.node,
};

export default Attachment;
