import { Input } from '@gonurture/design-system';
import SelectedItemBadge from './components/selected-item-badge/SelectedItemBadge';
import { TEACHER_ROLES } from './constants';
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

const TeacherOnboardingTeacherRole = ({
  selectedRoles,
  onSelect,
  onRemoveSelectedRole,
}) => {
  const [rolesToDisplay, setRolesToDisplay] = useState([]);

  const selectedRolesClassNames = 'bg-[#F4EBFF] border-[#7F56D9]';
  const baseRolesClassNames = 'border-[#D0D5DD] hover:bg-gray-50';

  const handleInputChange = (e) => {
    const query = e.target.value;
    search(query);
  };

  const reArrange = (roles) => {
    if (selectedRoles.length <= 0) {
      return sortBy(roles, ['name']);
    }

    // Separate the selected subject and others
    const selected = roles.filter((role) => selectedRoles.includes(role.name));
    const others = roles.filter((role) => !selectedRoles.includes(role.name));

    // Sort the others alphabetically
    const sortedOthers = sortBy(others, ['name']);

    // Combine selected subject at the top with the sorted others
    return [...selected, ...sortedOthers];
  };

  const search = (query) => {
    if (!query) return setRolesToDisplay(() => reArrange(TEACHER_ROLES));

    const lowerCaseQuery = query.toLowerCase();
    const filteredRoles = TEACHER_ROLES.filter((subject) =>
      subject.name.toLowerCase().startsWith(lowerCaseQuery),
    );

    setRolesToDisplay(() => reArrange(filteredRoles));
  };

  const handleSelectRole = (role) => {
    onSelect(role.name);
  };

  const removeSelectedRole = (role) => {
    onRemoveSelectedRole(role);
  };

  useEffect(() => {
    setRolesToDisplay(() => reArrange(TEACHER_ROLES));
  }, [selectedRoles]);

  return (
    <div>
      <div className='mb-8'>
        <div className='text-[#344054] text-xl font-semibold'>
          What best describes your role in this class?
        </div>

        <div className='text-[#667085]'>Select all that apply</div>
      </div>

      <div className='mb-8'>
        <Input placeholder='Search' onChange={handleInputChange} />
      </div>

      {selectedRoles.length > 0 && (
        <div className='mb-3 flex space-x-2 flex-wrap'>
          {selectedRoles.map((role) => (
            <SelectedItemBadge
              key={role}
              item={role}
              onRemove={() => removeSelectedRole(role)}
            />
          ))}
        </div>
      )}

      <div className='md:flex md:flex-wrap'>
        {rolesToDisplay.map((role, index) => (
          <div key={index} className='w-full md:w-1/2 mb-3'>
            <div
              className={`${index % 2 === 0 ? 'md:mr-1' : 'md:ml-1'} ${selectedRoles.includes(role.name) ? selectedRolesClassNames : baseRolesClassNames} text-sm font-medium text-[#344054] p-2 rounded border cursor-pointer flex`}
              onClick={() => handleSelectRole(role)}
            >
              <div>{role.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TeacherOnboardingTeacherRole.defaultProps = {
  selectedRoles: [],
  onSelect: () => {},
  onRemoveSelectedRole: () => {},
};

TeacherOnboardingTeacherRole.propTypes = {
  selectedRoles: PropTypes.array,
  onSelect: PropTypes.func,
  onRemoveSelectedRole: PropTypes.func,
};

export default TeacherOnboardingTeacherRole;
