import PropTypes from 'prop-types';
import { Avatar, pluralize } from '@gonurture/design-system';

const RubricsTable = ({ assignment }) => {
  const rubrics = assignment?.rubrics_json || [];
  const userAssignments = assignment?.user_works;

  const getUserAvatar = (userAssignmentId) => {
    const userAssignment = userAssignments?.find(
      (ua) => ua.id === userAssignmentId,
    );
    return userAssignment?.user?.avatar_url;
  };

  return (
    <div className='rounded-lg border border-[#EAECF0] shadow-sm'>
      <div className='px-3 py-6'>
        {assignment?.rubrics_grade_display === 'points' && (
          <>
            <div className='font-semibold text-[#101828] text-lg mb-2'>
              Rubric Points Available : {assignment?.max_points}
            </div>
            <div className='text-[#475467] text-sm'>
              Points are split evenly across all learning outcomes
            </div>
          </>
        )}

        {assignment?.rubrics_grade_display === 'labels' && (
          <div className='font-semibold text-[#101828] text-lg mb-2'>
            Rubrics is graded with Labels
          </div>
        )}
      </div>

      {rubrics.length > 0 && (
        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white border'>
            <thead>
              <tr className='bg-[#F4F3FF] text-left border-b text-xs'>
                <th className='p-3 border-x min-w-[200px]'>Learning Outcome</th>
                {rubrics[0].columns.map((column) => (
                  <th className='min-w-[200px] p-3 border-x' key={column.id}>
                    <div className='flex justify-between align-center'>
                      <span>{column.name}</span>
                      {assignment?.rubrics_grade_display !== 'labels' && (
                        <span className='text-[#7F56D9]'>
                          {column.point} {pluralize('point', column.point)}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {rubrics.map((rubric, index) => {
                return (
                  <tr key={index}>
                    <td className='border border-l-0'>
                      <div className='p-3 flex items-center'>
                        <div className='flex items-center justify-center w-8 h-8 bg-[#7F56D9] rounded-full text-white'>
                          {index + 1}
                        </div>
                        <div className='flex-grow ml-2 text-sm font-semibold'>
                          {rubric['objective_name']}
                        </div>
                      </div>
                    </td>
                    {rubric.columns.map((column, index) => (
                      <td className='p-3 border text-sm' key={index}>
                        <div className='mb-2'>{column.value}</div>

                        {column?.rubric_grades?.length > 0 && (
                          <div className='flex flex-wrap'>
                            {column?.rubric_grades?.map(
                              (rubricGrade, index) => (
                                <div className='m-[-4px]' key={index}>
                                  <Avatar
                                    className='border-2 border-[#FFFFFF]'
                                    size='md'
                                    src={getUserAvatar(
                                      rubricGrade.user_work_id,
                                    )}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

RubricsTable.propTypes = {
  assignment: PropTypes.object.isRequired,
};

export default RubricsTable;
