import DashboardHeader from './dashboard-header/DashboardHeader';
import { Separator } from '@gonurture/design-system';
import DashboardGraph from './dashboard-graph/DashboardGraph';
import { useState } from 'react';

const Dashboard = () => {
  const [selectedStudent, setSelectedStudent] = useState(null);

  return (
    <div className='border border-[#D0D5DD] shadow-sm p-3 sm:p-5 lg:p-8 rounded-lg'>
      <DashboardHeader onSelectStudent={setSelectedStudent} />
      <Separator className='my-8' />
      <DashboardGraph selectedUserId={selectedStudent} />
    </div>
  );
};

export default Dashboard;
