import { AiAssistantChat, useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { addMessage, getMessages, runThread } from 'apis/';
import { useEffect, useState } from 'react';
import { convertMessageToChatFormat } from 'lib/ai_helpers';
import {
  useAiFeedbackTeacherActions,
  useClassroom,
  useCurrentAssignment,
} from 'store/selectors';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import StorageService from 'services/StorageService';
import { setAiFeedback } from 'store/reducers/ai-feedback-reducer';

const FeedbackAiChat = ({ assignmentId, userAssignment }) => {
  const [messages, setMessages] = useState([]);
  const [aiThinking, setAiThinking] = useState(false);
  const [error, setError] = useState(''); // eslint-disable-line
  const [streaming, setStreaming] = useState(false);
  const [streamMessage, setStreamMessage] = useState('');
  const [teacherActions, setTeacherActions] = useState([]);
  const [actionsTimerID, setActionsTimerID] = useState();

  const user = StorageService.getObject('user');
  const { channelId } = useClassroom();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();

  const currentAssignment = useCurrentAssignment(assignmentId);

  const {
    learningObjectives: teacherActionLearningObjectives,
    grade: teacherActionGrade,
    personalFeedback: teacherActionPersonalFeedback,
    rubricsGrade: teacherActionRubricsGrade,
  } = useAiFeedbackTeacherActions();

  const getInitialMessages = async () => {
    try {
      setMessages([]);
      setAiThinking(true);
      const { messages } = await getMessages(
        channelId,
        userAssignment?.thread_id,
      );
      setMessages(() => {
        return messages?.map((message) => convertMessageToChatFormat(message));
      });
      setAiThinking(false);
    } catch (e) {
      errorHandler(e, () => {
        setAiThinking(false);
        toast({
          description: 'Error occurred when fetching messages',
          variant: 'destructive',
        });
      });
    }
  };

  const handleSubmitPrompt = async (prompt) => {
    const newUserMessage = {
      contents: [{ type: 'text', value: prompt }],
      sender: { name: user.display_name, avatar: user.avatar_url },
      requiresUserAction: false,
    };

    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setAiThinking(true);
    setStreaming(false);

    try {
      await addMessageToThread({
        content: prompt,
        thread_id: userAssignment?.thread_id,
      });
    } catch (error) {
      console.error('Failed to get AI response:', error);
      setError('Failed to get AI response');
    } finally {
      setAiThinking(false);
    }
  };

  const addMessageToThread = async (promptData, teacherActions = {}) => {
    try {
      setError(null); // clear any existing error in case the user is retrying

      const data = {
        ...promptData,
        ...teacherActions,
        user_work_id: userAssignment?.id,
      };

      if (teacherActions?.teacher_actions) {
        setTeacherActions([]);
      }

      const response = await addMessage(channelId, data);
      const threadId = response.id;

      runThread({
        threadId,
        channelId,
        onMessage: handleStreamEvent,
      });
    } catch (error) {
      setError(error);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.learning_objectives_feedback': {
        dispatch(setAiFeedback({ learningObjectives: messageEvent.data }));
        break;
      }

      case 'assistant.grade': {
        dispatch(setAiFeedback({ grade: messageEvent.data }));
        break;
      }

      case 'assistant.personal_feedback': {
        dispatch(setAiFeedback({ personalFeedback: messageEvent.data }));
        break;
      }

      case 'assistant.rubrics_grade': {
        dispatch(setAiFeedback({ rubricsGrade: messageEvent.data }));
        break;
      }

      case 'thread.message.delta': {
        const content = messageEvent?.data.delta.content[0].text.value;
        setStreaming(true);
        setStreamMessage((prev) => prev + content);
        break;
      }

      case 'thread.message.completed':
        handleMessageCompleted(messageEvent);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  };

  const handleErrorEvent = (event) => {
    console.log('error event', event);
    setError('Error occurred while generating assessment');
    setStreaming(false);
    setStreamMessage('');
    setAiThinking(false);
  };

  const handleMessageCompleted = (messageEvent) => {
    setMessages((prev) => {
      return [
        ...prev,
        ...[
          {
            contents: [
              { type: 'text', value: messageEvent.data.content[0].text.value },
            ],
            requiresUserAction: false,
          },
        ],
      ];
    });

    setError('');
    setAiThinking(false);
    setStreamMessage('');
    setStreaming(false);
    // firstTokenSent = false;
    // metricService.track({ event: 'ai_success', properties: { scope: 'assistant_chat' } });
    // trackDuration('assistant_chat');
    // scrollToRecentChat();
  };

  const cleanUp = (message) => {
    return message.replace(/【[0-9]{1,2}:[0-9]{1,2}†source】/g, '');
  };

  const getAttachmentFromAiFileId = (fileId) => {
    return userAssignment?.submissions?.[0]?.attachments.find(
      (attachment) => attachment.ai_file_id === fileId,
    );
  };

  const replaceWithFileId = (text) => {
    // Regular expression to match the different variations of the prefix
    const regex = /nurture[_-]?attachment[_-]?(assistant-\w+)/g;

    // Replace each match with just the extracted file ID
    return text.replace(regex, (match, fileId) => {
      const attachment = getAttachmentFromAiFileId(fileId);
      return (
        '<b>' +
        attachment?.filename +
        '</b>  <a target="_blank" class="text-blue-500" href="' +
        attachment?.view_url +
        '">view</a>'
      );
    });
  };

  const updateTeacherActions = (action) => {
    const updatedActions = teacherActions.filter(
      (prevAction) => prevAction.type !== action.type,
    );
    updatedActions.push(action);

    setTeacherActions(updatedActions);

    clearTimeout(actionsTimerID);

    const timerId = setTimeout(() => {
      addMessageToThread(
        { thread_id: userAssignment?.thread_id },
        { teacher_actions: updatedActions },
      );
    }, 5000);

    setActionsTimerID(timerId);
  };

  useEffect(() => {
    if (userAssignment?.thread_id) {
      getInitialMessages();
    }
  }, [userAssignment.id]);

  useEffect(() => {
    if (teacherActionLearningObjectives) {
      updateTeacherActions({
        type: 'learning_objectives',
        value: teacherActionLearningObjectives,
      });
    }
  }, [teacherActionLearningObjectives]);

  useEffect(() => {
    if (teacherActionGrade) {
      updateTeacherActions({
        type: 'grade',
        value: teacherActionGrade,
      });
    }
  }, [teacherActionGrade]);

  useEffect(() => {
    if (teacherActionPersonalFeedback) {
      updateTeacherActions({
        type: 'personal_feedback',
        value: teacherActionPersonalFeedback,
      });
    }
  }, [teacherActionPersonalFeedback]);

  useEffect(() => {
    if (
      teacherActionRubricsGrade &&
      teacherActionRubricsGrade.length === currentAssignment?.objectives?.length
    ) {
      updateTeacherActions({
        type: 'rubrics_grade',
        value: teacherActionRubricsGrade,
      });
    }
  }, [teacherActionRubricsGrade]);

  return (
    <div
      className='
        prose-table:table-auto prose-table:border-collapse
        prose-table:border prose-table:overflow-x-auto prose-table:w-full
        prose-table:my-3 prose-th:border prose-th:p-2 prose-td:text-sm prose-th:text-sm
        prose-td:border prose-td:p-2 prose-tr:even:bg-gray-50
        prose-p:mb-2 prose-p:text-sm prose-headings:font-semibold
        prose-ul:list-disc prose-ul:list-inside prose-li:text-sm prose-li:mb-1 prose-ul:mb-2
        prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
    '
    >
      <AiAssistantChat
        messages={messages.map((message) => ({
          ...message,
          contents: message.contents.map((content) => ({
            ...content,
            value: replaceWithFileId(cleanUp(content.value)),
          })),
        }))}
        onSubmitPrompt={handleSubmitPrompt}
        aiThinking={aiThinking}
        streaming={streaming}
        streamText={streamMessage}
      />
    </div>
  );
};

FeedbackAiChat.defaultProps = {
  userAssignment: {},
};

FeedbackAiChat.propTypes = {
  userAssignment: PropTypes.object,
  assignmentId: PropTypes.string.isRequired,
};

export default FeedbackAiChat;
