import StorageService from 'services/StorageService';
import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const TeacherOnboardingPrompt = ({ onNext }) => {
  const user = StorageService.getObject('user');

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <div className='font-merriweather text-[#3D2E55] text-4xl mb-8'>
          Hello {user.display_name.split()[0]}. 👋
        </div>
        <div className='font-merriweather text-[#3D2E55] text-lg sm:text-xl md:text-2xl flex items-center flex-wrap justify-center mb-8'>
          <span className='min-w-[116px] sm:mr-2'>Welcome to</span>
          <NurtureIcon className='mb-4' icon='nurture-logo' />, the AI Teaching
          Assistant.
        </div>
        <div className='text-[#535862] text-center mb-8'>
          We’re excited to have you on board! Before you start using Nurture
          with this class, we’d like to ask a few quick questions so the{' '}
          <strong>Nurture Assistant</strong> can help you and your students more
          effectively. Don’t worry, you can always update these choices later in
          your classroom settings.
        </div>

        <div>
          <Button
            className='bg-[#7F56D9] border-[#7F56D9] text-white hover:bg-[#7F56D9] hover:border-[#7F56D9] w-full flex items-center font-semibold text-lg'
            onClick={onNext}
          >
            Let's Get Started
            <NurtureIcon className='ml-2' icon='white-arrow-right' />
          </Button>
        </div>
      </div>
    </div>
  );
};

TeacherOnboardingPrompt.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default TeacherOnboardingPrompt;
