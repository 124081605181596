import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCurrentAssignment } from 'store/selectors';
import { addAssignment } from 'store/reducers/assignments-reducer';
import { useDispatch } from 'react-redux';
import {
  AssessmentSummaryTab,
  AverageTimeSpentOnFeedbackCard,
  EngagementScoreCard,
  NurtureIcon,
  SubmissionsCarousel,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import { assignmentShow, userAssignmentShow } from 'apis';
import UserAssignmentModal from './user-assignment-modal/UserAssignmentModal';
import {
  clearUserAssignments,
  updateUserAssignment,
} from 'store/reducers/user-assignments-reducer';
// import FeedbackSettingsCard from './feedback-settings/FeedbackSettingsCard';
import StudentsSubmissionsTable from './students-submissions-table/StudentsSubmissionsTable';
import FeedbackPublishCard from './feedback-publish-card/FeedbackPublishCard';
import AssignmentDetails from '../../user-assignment/user-assignment-show/AssignmentDetails';
import FeedbackObjectives from './feedback-objectives/FeedbackObjectives';
import RubricsTable from './rubrics-table/RubricsTable';
import AssignmentShowLoader from './AssignmentShowLoader';

const AssignmentShow = () => {
  const [userAssignmentLoading, setUserAssignmentLoading] = useState(null);
  const [userAssignmentModalOpened, setUserAssignmentModalOpened] =
    useState(false);
  const [selectedUserAssignment, setSelectedUserAssignment] = useState(null);
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('submissions'); // or assessment
  const { channelId, tenantId, assignmentId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const submittedWorks =
    currentAssignment?.user_works?.filter((user_work) => user_work.submitted) ||
    [];

  const handleBackButton = () =>
    navigate(`/teams/${tenantId}/channels/${channelId}`);

  const fetchAssignment = async () => {
    try {
      setAssignmentLoading(true);
      const assignment = await assignmentShow(channelId, assignmentId);
      dispatch(addAssignment(assignment));
      dispatch(clearUserAssignments()); // clear the userAssignment in the store
      setAssignmentLoading(false);
    } catch (e) {
      setAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  const handleSelectUserAssignment = async (userAssignment) => {
    try {
      setUserAssignmentLoading(userAssignment.id);
      const result = await userAssignmentShow(channelId, userAssignment.id);
      dispatch(updateUserAssignment(result));
      setUserAssignmentModalOpened(true);
      setSelectedUserAssignment(userAssignment);
      setUserAssignmentLoading(null);
    } catch (e) {
      setUserAssignmentLoading(null);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  useEffect(() => {
    fetchAssignment();
  }, []);

  return (
    <>
      {assignmentLoading && <AssignmentShowLoader />}

      {!assignmentLoading && (
        <div className='mt-5'>
          <div className='mb-8'>
            <div className='flex cursor-pointer' onClick={handleBackButton}>
              <NurtureIcon icon='black-arrow-left' className='mr-2' />
              <div className='text-sm font-semibold text-[#475467]'>
                Back to assessments
              </div>
            </div>
          </div>

          <div className='mb-8'>
            <AssessmentSummaryTab
              tab={activeTab}
              assessment={currentAssignment}
              onTabChange={setActiveTab}
            />
          </div>

          {activeTab === 'submissions' && (
            <>
              <div className='mb-8'>
                <div className='lg:flex'>
                  <SubmissionsCarousel
                    className='mb-3 lg:w-6/12 lg:mb-0 border lg:mr-3'
                    submissions={submittedWorks}
                    onOpenSubmission={handleSelectUserAssignment}
                  />

                  <div className='lg:w-6/12'>
                    <div className='mb-3'>
                      <EngagementScoreCard
                        maxEngagementScore={
                          currentAssignment?.engagement_score?.max_score
                        }
                        engagementScore={
                          currentAssignment?.engagement_score?.engagement_score
                        }
                      />
                    </div>

                    <div>
                      <AverageTimeSpentOnFeedbackCard
                        averageTimeString={
                          currentAssignment?.average_time_to_review_feedback ||
                          '?'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className='mb-8'>*/}
              {/*  <FeedbackSettingsCard />*/}
              {/*</div>*/}

              <div className='mb-8'>
                <StudentsSubmissionsTable
                  assignment={currentAssignment}
                  submissionLoading={userAssignmentLoading}
                  onSelectSubmission={handleSelectUserAssignment}
                />
              </div>

              <div className='mb-8'>
                {currentAssignment?.grade_display !==
                  'grade_display_rubrics' && (
                  <FeedbackObjectives assignment={currentAssignment} />
                )}

                {currentAssignment?.grade_display ===
                  'grade_display_rubrics' && (
                  <RubricsTable assignment={currentAssignment} />
                )}
              </div>

              <div className='mb-8'>
                <FeedbackPublishCard assignment={currentAssignment} />
              </div>

              <UserAssignmentModal
                defaultUserAssignmentId={selectedUserAssignment?.id}
                opened={userAssignmentModalOpened}
                onClose={() => setUserAssignmentModalOpened(false)}
              />
            </>
          )}

          {activeTab === 'assessment' && (
            <AssignmentDetails
              isTeacher={true}
              assignment={currentAssignment}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AssignmentShow;
