import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import { Emoji } from '@gonurture/design-system';

const StudentReflectionState = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};
  const reflection = feedback?.reflection || {
    emoji: 'happy',
    comment: 'I am happy',
  };

  return (
    <div>
      <div className='text-2xl mb-1'>My Reflection</div>
      <p className='text-[#344054] mb-5 text-sm'>{currentAssignment?.title}</p>

      <div className='mb-5'>
        <p className='text-[#344054] flex items-center'>
          You are{' '}
          <Emoji
            className='mx-1'
            width={20}
            height={20}
            confidence={reflection?.emoji}
          />{' '}
          with your teachers feedback.
        </p>
      </div>

      <div className='mb-5'>
        <div className='flex items-center mb-2'>
          <span>🎯</span>
          <p className='ml-2 font-semibold'>New Short Term Goal</p>
        </div>
        <p className='text-[#344054] mb-2'>
          Enhance the precision and clarity of your short answer responses.
        </p>
      </div>

      <div className='mb-5'>
        <div className='flex items-center mb-2'>
          <span>🏆</span>
          <p className='font-semibold ml-2'>New Long Term Goal</p>
        </div>
        <p className='text-[#344054] mb-2'>
          Develop a coherent and well-structured essay writing style.
        </p>
      </div>

      <div className='mb-5'>
        <p className='font-semibold'>Personal Comment For Teacher</p>
        <p className='text-[#344054] mb-2'>{reflection.comment}</p>
      </div>
    </div>
  );
};

export default StudentReflectionState;
