import { useNavigate, useParams } from 'react-router-dom';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import {
  Button,
  Input,
  Label,
  NurtureIcon,
  RubricsDropdown,
  Separator,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { createAssignment, deleteThread } from 'apis/';
import {
  addAssignment,
  // addAssignmentAttachment,
  // removeAssignmentAttachment,
  // updateAssignmentAttachment,
} from 'store/reducers/assignments-reducer';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentAssistantFormSchema } from 'form-schemas/';
import AssignmentTypeHeader from 'components/assignment-type-header/AssignmentTypeHeader';
import AssessmentAssistantLoading from './AssessmentAssistantLoading';
import PulsatingLoader from 'components/pulsating-loader/PulsatingLoader';
import MultiSelectWithCheckboxOptions from 'components/multi-select-with-checkbox-options/MultiSelectWithCheckboxOptions';
import { clearAiAssignment } from 'store/reducers/ai-assignment-reducer';
import RubricsCriteriaLabelsCount from './RubricsCriteriaLabelsCount';

const AssignmentAssistant = () => {
  const [assignmentCreateLoading, setAssignmentCreateLoading] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const [showAssessmentAssistantLoading, setShowAssessmentAssistantLoading] =
    useState(false);
  const [promptData, setPromptData] = useState({});
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const navigate = useNavigate();

  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const formConfig = useForm({
    resolver: yupResolver(assignmentAssistantFormSchema),
    defaultValues: {
      curriculum_boundary: false,
      grade_display: `grade_display_${assignmentType}`,
    },
  });

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    control,
    handleSubmit,
  } = formConfig;

  const watchCriteriaLabels = watch('criteria_labels');

  const assessmentDisplayOptions = [
    { label: 'Multiple Choice Questions', value: 'Multiple Choice Questions' },
    { label: 'Essay Style', value: 'Essay Style' },
    { label: 'Matching Exercises', value: 'Matching Exercises' },
    { label: 'True/False Statements', value: 'True/False Statements' },
    {
      label: 'Multiple Choice Statements',
      value: 'Multiple Choice Statements',
    },
    { label: '"Fill In" Items', value: '"Fill In" Items' },
    { label: 'Portfolios', value: 'Portfolios' },
    { label: 'Presentations', value: 'Presentations' },
    {
      label: 'Reading Comprehension Questions',
      value: 'Reading Comprehension Questions',
    },
    { label: 'Document Questions', value: 'Document Questions' },
    { label: 'Journals', value: 'Journals' },
    { label: 'Projects', value: 'Projects' },
    { label: 'Quizzes', value: 'Quizzes' },
    { label: 'Case Studies', value: 'Case Studies' },
    { label: 'Diagrams/Concept Maps', value: 'Diagrams/Concept Maps' },
  ];

  // const nonMarkingSchemeAttachments = currentAssignment?.attachments?.filter(
  //   (attachment) => attachment.metadata?.purpose !== 'Marking Scheme',
  // );
  //
  // const markingSchemeAttachments = currentAssignment?.attachments?.filter(
  //   (attachment) => attachment.metadata?.purpose === 'Marking Scheme',
  // );

  const goToAssignmentNewPage = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/new/${assignmentType}`,
    );
  };

  const skipAndCreateAssignmentManually = async () => {
    dispatch(clearAiAssignment());
    if (currentAssignment?.thread_id) {
      try {
        await deleteThread(channelId, currentAssignment?.thread_id);
      } catch (e) {
        errorHandler(e);
      }
    }
    goToAssignmentNewPage();
  };

  const handleBack = () =>
    navigate(`/teams/${tenantId}/channels/${channelId}/assignment/type`);

  const createNewAssignment = async () => {
    try {
      setAssignmentCreateLoading(true);
      const assignment = await createAssignment(channelId, assignmentType);
      dispatch(addAssignment({ ...assignment, new: true }));
      setAssignmentId(assignment.id);
      setAssignmentCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setAssignmentCreateLoading(false);
        toast({
          description: 'Error occurred during assignment creation',
          variant: 'destructive',
        });
        dispatch(
          setAppError({ message: 'Error occurred during assignment creation' }),
        );
      });
    }
  };

  // const handleAttachmentReset = (attachment) => {
  //   dispatch(addAssignmentAttachment({ id: assignmentId, attachment }));
  // };
  //
  // const handleAttachmentDelete = (attachment) => {
  //   dispatch(removeAssignmentAttachment({ id: assignmentId, attachment }));
  // };
  //
  // const handleAttachmentUpdate = (attachment) => {
  //   dispatch(updateAssignmentAttachment({ id: assignmentId, attachment }));
  // };

  const onSubmit = (data) => {
    setPromptData(() => data);
    setShowAssessmentAssistantLoading(true);
  };

  const SkipAssistantButton = () => (
    <div
      className='text-center text-[#7341C6] font-semibold cursor-pointer flex flex-wrap'
      onClick={skipAndCreateAssignmentManually}
    >
      <div className=''>Skip assistant and create this task manually</div>
      <NurtureIcon className='ml-2' icon='purple-arrow-right' />
    </div>
  );

  useEffect(() => {
    setValue('assessment_grading_type', assignmentType);
    createNewAssignment();
  }, []);

  return (
    <div>
      {assignmentCreateLoading && <PulsatingLoader text='Loading...' />}

      {!assignmentCreateLoading && (
        <>
          {!showAssessmentAssistantLoading && (
            <div className='py-8'>
              <HeaderWithBackButton
                header={
                  <AssignmentTypeHeader assignmentType={assignmentType} />
                }
                onBack={handleBack}
              />

              <div className='mb-4 flex justify-center'>
                <SkipAssistantButton />
              </div>

              {/*Teacher Instruction*/}
              <div className='rounded-lg p-[2px] bg-gradient-to-r from-[#4D6AD0] to-[#FF9D7E] lg:w-1/2 md:w-3/4 md:mx-auto'>
                <div className='bg-white rounded-lg p-3 md:px-6 md:py-4'>
                  {/*Ai Nudge*/}
                  <div>
                    <div className='flex mb-2'>
                      <NurtureIcon icon='gradient-stars' className='mr-2' />
                      <div className='font-semibold text-[#101828]'>
                        Generate task with the Nurture Assistant
                      </div>
                    </div>

                    <div className='text-[#344054] text-sm'>
                      Add some basic info about this task and let the Nurture
                      Assistant generate the full assessment content.{' '}
                      <strong>
                        You can refine this assessment on the next screen.
                      </strong>
                    </div>
                  </div>

                  <Separator className='my-5' />

                  <div className='mb-10'>
                    <TextArea
                      id='teacher_instruction'
                      placeholder='Enter Topic...'
                      label='What topic do you want this assessment to be on?'
                      error={errors?.teacher_instruction?.message}
                      {...register('teacher_instruction')}
                    />
                  </div>

                  {/*/!*Attachments Upload*!/*/}
                  {/*<div className='mb-10'>*/}
                  {/*  <Label>Create questions from an attachment</Label>*/}
                  {/*  <div className='text-sm mt-1 text-[#475467]'>*/}
                  {/*    Upload a reading comprehension, photo of a textbook, paste a*/}
                  {/*    URL of a webpage, YouTube video or anything you want and the*/}
                  {/*    Nurture Assistant will generate questions on it.*/}
                  {/*  </div>*/}

                  {/*  <div className='mt-3'>*/}
                  {/*    <AttachmentsUploadAndDisplay*/}
                  {/*      attachableParams={{*/}
                  {/*        work_id: currentAssignment?.id,*/}
                  {/*      }}*/}
                  {/*      showUploaderChildren={true}*/}
                  {/*      showAttachmentsChildren={true}*/}
                  {/*      attachments={nonMarkingSchemeAttachments}*/}
                  {/*      onAddAttachment={handleAttachmentReset}*/}
                  {/*      onUpdateAttachment={handleAttachmentUpdate}*/}
                  {/*      onRemoveAttachment={handleAttachmentDelete}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*/!*Marking Scheme*!/*/}
                  {/*<div className='mb-10'>*/}
                  {/*  <Label>Create Marking scheme from an attachment</Label>*/}
                  {/*  <div className='text-sm mt-1 text-[#475467]'>*/}
                  {/*    Upload a marking scheme as an attachment here and the*/}
                  {/*    Nurture Assistant will grade students work based on this.*/}
                  {/*  </div>*/}

                  {/*  <div className='mt-3 mb-10'>*/}
                  {/*    <AttachmentsUploadAndDisplay*/}
                  {/*      attachableParams={{*/}
                  {/*        work_id: currentAssignment?.id,*/}
                  {/*        metadata: { purpose: 'Marking Scheme' },*/}
                  {/*      }}*/}
                  {/*      attachments={markingSchemeAttachments}*/}
                  {/*      buttonView={true}*/}
                  {/*      buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'*/}
                  {/*      buttonViewContent={*/}
                  {/*        <div className='flex items-center'>*/}
                  {/*          Upload Attachments{' '}*/}
                  {/*          <NurtureIcon className='ml-2' icon='black-plus' />*/}
                  {/*        </div>*/}
                  {/*      }*/}
                  {/*      onAddAttachment={handleAttachmentReset}*/}
                  {/*      onUpdateAttachment={handleAttachmentUpdate}*/}
                  {/*      onRemoveAttachment={handleAttachmentDelete}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*Assessment Type*/}
                  <div className='mb-10'>
                    <Controller
                      name='assessment_description_types'
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <Label>Assessment Type</Label>
                            <MultiSelectWithCheckboxOptions
                              values={value}
                              options={assessmentDisplayOptions}
                              error={
                                errors?.assessment_description_types?.message
                              }
                              onChange={onChange}
                            />
                          </>
                        );
                      }}
                    />
                    <p className='text-sm text-[#475467]'>
                      Assessment description will contain these types of
                      questions.
                    </p>
                  </div>

                  {/*Maximum marks*/}
                  {assignmentType === 'points' && (
                    <div className='mb-10'>
                      <Input
                        className='w-[200px]'
                        label='Maximum Marks'
                        placeholder='e.g 100'
                        error={errors?.max_points?.message}
                        {...register('max_points')}
                      />
                    </div>
                  )}

                  {/*Rubrics Column Dropdown*/}
                  {assignmentType === 'rubrics' && (
                    <>
                      <div className='mb-10'>
                        <Controller
                          name='criteria_labels'
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <>
                                <Label>Criteria Labels</Label>
                                <RubricsDropdown
                                  error={errors?.criteria_labels?.message}
                                  onSelect={onChange}
                                  value={value}
                                />
                              </>
                            );
                          }}
                        />

                        {watchCriteriaLabels === 'custom_criteria' && (
                          <Controller
                            name='labels_count'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RubricsCriteriaLabelsCount
                                  value={value}
                                  error={errors?.labels_count?.message}
                                  onChange={onChange}
                                />
                              );
                            }}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {/*Curriculum Boundary*/}
                  <div className='mb-10'>
                    <div className='flex space-x-3 items-start'>
                      <Controller
                        name='curriculum_boundary'
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Switch
                              name='curriculum_boundary'
                              className='data-[state=checked]:bg-[#7F56D9]'
                              checked={value}
                              onCheckedChange={onChange}
                            />
                          );
                        }}
                      />
                      <div className='flex-grow'>
                        <div className='mb-2 text-[#344054] font-medium'>
                          Curriculum Boundary
                        </div>
                        <div className='text-[#475467]'>
                          Nurture Assistant will generate content based on your
                          uploaded curriculum.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mb-5'>
                    <Button
                      className='bg-[#7F56D9] hover:bg-[#7F56D9] text-white w-full'
                      onClick={handleSubmit(onSubmit, (e) => console.log(e))}
                    >
                      Generate Task
                    </Button>
                  </div>
                </div>
              </div>

              <div className='mt-4 flex justify-center'>
                <SkipAssistantButton />
              </div>
            </div>
          )}

          {showAssessmentAssistantLoading && (
            <AssessmentAssistantLoading
              assignmentId={assignmentId}
              promptData={promptData}
              onCancel={() => setShowAssessmentAssistantLoading(false)}
              onDone={goToAssignmentNewPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AssignmentAssistant;
