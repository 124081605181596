import StorageService from 'services/StorageService';

/**
 * Handles errors from API calls.
 *
 * This function takes the axiosError instance, modifies the error if necessary, and sets other states.
 * It should be used to wrap all API calls to ensure consistent error handling.
 * It should always return the error.
 *
 * @param {Object} error - The error object received from the API call.
 * @returns {Object} - The modified error object.
 */
export const handleApiError = (error) => {
  if (isMicrosoftTeamsPermissionError(error)) {
    handleMicrosoftTeamsPermissionError();
  }

  return error;
};

const isMicrosoftTeamsPermissionError = (error) => {
  return (
    error?.response?.data?.error_class === 'MicrosoftTeams::PermissionsError'
  );
};

const handleMicrosoftTeamsPermissionError = () => {
  const channelId = StorageService.get('channelId');
  const tenantId = StorageService.get('tenantId');

  window.location.href = `/adminconsent?channel_id=${channelId}&tenant_id=${tenantId}`;
};
