import StorageService from 'services/StorageService';
import UserAssignmentList from './user-assignment-list/UserAssignmentList';
import { useState } from 'react';
import { NAVS } from '../teacher-home/TeacherHomeNav';
import greeting from '../shared/greetings';
import StudentHomeNav from './StudentHomeNav';
import Dashboard from '../shared/dashboard/Dashboard';

const Home = () => {
  const [activeTab, setActiveTab] = useState(NAVS.tasks);

  const user = StorageService.getObject('user');

  return (
    <div className='py-8'>
      <div className='mb-8 text-2xl mt-10 md:mt-0'>
        &#128075; {greeting()} {user.display_name.split()[0]}
      </div>

      <StudentHomeNav activeTab={activeTab} onTabChange={setActiveTab} />

      {activeTab === NAVS.tasks && (
        <div className='mt-10'>
          <UserAssignmentList />
        </div>
      )}

      {activeTab === NAVS.dashboard && (
        <div className='mt-10'>
          <Dashboard />
        </div>
      )}
    </div>
  );
};

export default Home;
