import { Button } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const PageWithIllustration = ({
  illustration,
  title,
  subtitle,
  buttonText,
  onAction,
}) => {
  return (
    <div className='flex items-center justify-center'>
      <div className='w-full md:w-3/4 xl:w-1/2 text-center'>
        <div className='mb-8 flex justify-center'>
          <img alt='illustration' className='w-[450px]' src={illustration} />
        </div>
        {title && (
          <div className='font-semibold text-lg text-[#344054] mb-2'>
            {title}
          </div>
        )}
        {subtitle && <div className='text-[#475467] mb-6'>{subtitle}</div>}

        {buttonText && (
          <Button
            className='bg-[#7F56D9] border-[#7F56D9] text-white hover:bg-[#7F56D9] hover:border-[#7F56D9]'
            onClick={onAction}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

PageWithIllustration.defaultProps = {
  title: '',
  subtitle: '',
  buttonText: '',
  onAction: () => {},
};

PageWithIllustration.propTypes = {
  illustration: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  onAction: PropTypes.func,
};

export default PageWithIllustration;
