export const SUBJECTS = [
  { name: 'Accounting', icon: 'grey-balance' },
  { name: 'Active Leisure Studies', icon: 'grey-file' },
  { name: 'Agricultural Science', icon: 'grey-microscope' },
  { name: 'Agriculture / Horticulture', icon: 'grey-microscope' },
  { name: 'Ancient Greek', icon: 'grey-clock-rewind' },
  { name: 'Applied Maths', icon: 'grey-calculator' },
  { name: 'Arabic', icon: 'grey-calculator' },
  { name: 'Art and Design', icon: 'grey-palette' },
  { name: 'Biology', icon: 'grey-microscope' },
  { name: 'Chemistry', icon: 'grey-microscope' },
  { name: 'Childcare / Community Care', icon: 'grey-microscope' },
  { name: 'Citizenship', icon: 'grey-microscope' },
  { name: 'Computer Science', icon: 'grey-code-snippet' },
  { name: 'Computing', icon: 'grey-clock-rewind' },
  { name: 'Construction Studies', icon: 'grey-building' },
  { name: 'Craft and Design', icon: 'grey-file' },
  { name: 'Dance', icon: 'grey-file' },
  { name: 'Design and Communications Graphics', icon: 'grey-file' },
  { name: 'Drama', icon: 'grey-file' },
  { name: 'Economics', icon: 'grey-calculator' },
  { name: 'Engineering', icon: 'grey-building' },
  { name: 'English', icon: 'grey-file' },
  { name: 'English Language and Literature', icon: 'grey-file' },
  { name: 'English and Communication', icon: 'grey-file' },
  { name: 'Fine Arts', icon: 'grey-palette' },
  { name: 'French', icon: 'grey-file' },
  { name: 'Gaeilge', icon: 'grey-file' },
  { name: 'Geography', icon: 'grey-file' },
  { name: 'German', icon: 'grey-file' },
  { name: 'Graphics and Construction Studies', icon: 'grey-building' },
  { name: 'Hair and Beauty', icon: 'grey-file' },
  { name: 'Hebrew Studies', icon: 'grey-clock-rewind' },
  { name: 'Hindi', icon: 'grey-file' },
  { name: 'History', icon: 'grey-clock-rewind' },
  { name: 'Home Economics', icon: 'grey-file' },
  { name: 'Hotel Catering and Tourism', icon: 'grey-file' },
  { name: 'Humanities', icon: 'grey-file' },
  { name: 'Information and Communications Technology', icon: 'grey-building' },
  { name: 'Islamic Studies', icon: 'grey-file' },
  { name: 'Italian', icon: 'grey-file' },
  { name: 'Languages', icon: 'grey-file' },
  { name: 'Latin', icon: 'grey-file' },
  { name: 'Leisure and Recreation', icon: 'grey-file' },
  { name: 'Malayalam', icon: 'grey-file' },
  { name: 'Marketing', icon: 'grey-line-chart' },
  { name: 'Mathematical Applications', icon: 'grey-calculator' },
  { name: 'Mathematics', icon: 'grey-calculator' },
  { name: 'Modern Foreign Languages', icon: 'grey-file' },
  { name: 'Music', icon: 'grey-file' },
  { name: 'Office Administration and Customer Care', icon: 'grey-file' },
  { name: 'Other', icon: 'grey-file' },
  { name: 'Physical Education', icon: 'grey-file' },
  { name: 'Physics', icon: 'grey-microscope' },
  { name: 'Physics and Chemistry', icon: 'grey-microscope' },
  { name: 'Politics and Society', icon: 'grey-file' },
  { name: 'Psychology', icon: 'grey-file' },
  { name: 'Relationships and Health Education', icon: 'grey-file' },
  { name: 'Religious Education', icon: 'grey-file' },
  { name: 'Science (General)', icon: 'grey-microscope' },
  { name: 'Sex Education', icon: 'grey-beaker' },
  { name: 'Sex and Relationship Education', icon: 'grey-beaker' },
  { name: 'Sign Language', icon: 'grey-file' },
  { name: 'Social Education', icon: 'grey-file' },
  { name: 'Social Studies', icon: 'grey-file' },
  { name: 'Social, Personal and Health Education', icon: 'grey-file' },
  { name: 'Spanish', icon: 'grey-file' },
  { name: 'Subjects Career Guidance', icon: 'grey-file' },
  { name: 'Technology', icon: 'grey-building' },
  { name: 'The Sciences', icon: 'grey-microscope' },
  { name: 'Transition Year', icon: 'grey-file' },
  { name: 'Wellbeing', icon: 'grey-file' },
  { name: 'Woodwork', icon: 'grey-building' },
  { name: 'Work Experience', icon: 'grey-file' },
];

export const TEACHER_ROLES = [
  { name: 'Teacher' },
  { name: 'Key Stage Leader' },
  { name: 'Team-teacher' },
  { name: 'Special Needs Assistant' },
  { name: 'Teaching Assistant' },
  { name: 'I.T. Co-ordinator' },
  { name: 'After-school Programme Coordinator' },
  { name: 'School Principal' },
  { name: 'School Deputy Principal' },
  { name: 'Career Guidance Counsellor' },
  { name: 'School Counsellor' },
  { name: 'School Psychologist' },
  { name: 'Home School Liaison Teacher' },
  { name: 'Student Support Team Member' },
  { name: 'Special Education Needs Coordinator' },
  { name: 'Critical Incident Management Team Member' },
  { name: 'Wellbeing Coordinator' },
  { name: 'Wellbeing Team Member' },
  { name: 'Year Head' },
  { name: 'Form Tutors' },
  { name: 'School Completion Officer' },
  { name: 'Behaviour Support Teacher' },
  { name: 'Education Welfare Officer' },
  { name: 'Subject Inspector' },
  { name: 'Whole-School Inspector' },
  { name: 'Subject Coordinator' },
  { name: 'Learning Support Teacher' },
  { name: 'Resource Teacher' },
  { name: 'English as an Additional Language (EAL) Teacher' },
  { name: 'Transition Year Coordinator' },
  { name: 'Extracurricular Activity Coordinator (e.g., Sports, Drama, Music)' },
  { name: 'Homework Club Coordinator' },
  { name: 'Work Experience Coordinator' },
  { name: 'School Chaplain' },
  { name: 'Adult Education Coordinator' },
  { name: 'Outreach Programme Coordinator' },
  { name: 'Apprenticeship Coordinator' },
];
